import React, { useEffect, useState } from 'react'
import { useFormContext, Controller  } from "react-hook-form";
import intl from 'react-intl-universal';
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { countries } from '../../data/countries'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Autocomplete } from '@mui/material';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

export default function Traveler(props) {
    // console.log("traveler", props);
    const { register, setValue, getValues, watch, control, formState: { errors } } = useFormContext();
	// const { onChange, onBlur, name, ref } = register(`traveler.${props.type}[${props.number}].birthday`); 
	const[dateValue, setDateValue] = useState(null)
	const[IDExpirationDate, setIDExpirationDate] = useState(null)
	const[disabledDatePicker, setDisabledDatePicker] = useState(false)
	const[IDType, setIDType] = useState('')
	const[gender, setGender] = useState(0)
	let minDate = new Date(new Date().setFullYear(new Date().getFullYear()-100))
	let maxDate = new Date()
	if(props.type == "adult"){
		maxDate = maxDate.setFullYear(maxDate.getFullYear()-18)
	}else if(props.type == "child"){
		minDate = new Date(new Date().setFullYear(new Date().getFullYear()-12))
	}

	let defaultCountry = null
	if(props.value){
		for(let country of countries){
			if(props.value.nationality == country.abbr){
				defaultCountry = country
			}
		}
	}

	console.log('errors', errors);

    // console.log("get values", getValues());
    useEffect(()=>{
        if(props.value){
            setValue(`traveler.${props.type}[${props.number}]`, props.value)
			if(props.value.gender == "M"){
				setGender(2)
			}else if(props.value.gender == "F"){
				setGender(1)
			}

            setValue(`traveler.${props.type}[${props.number}].sex`, props.value.gender)
			setDateValue(props.value.birthday)
			setDisabledDatePicker(true)
			setIDExpirationDate(props.value.idExpirationDate)
			setIDType(props.value.idType);
			// setValue(`traveler.${props.type}[${props.number}].birthday`, props.value.birthday)
            // let birthdays = props.value.birthday.split('/')
            // setValue(`traveler.${props.type}[${props.number}].month`, birthdays[0])
            // setValue(`traveler.${props.type}[${props.number}].day`, parseInt(birthdays[1]))
            // setValue(`traveler.${props.type}[${props.number}].year`, birthdays[2])
        }
    },[])

	// const handleChange = (event) => {
	// 	console.log('id type', event.target.value)
	// 	setIDType(event.target.value);
	//   };


	  const handleChange = (event) => {
		setIDType(event.target.value)
		setValue(`traveler.${props.type}[${props.number}].idType`, event.target.value)
	  };

	return(
		<div className="theme-payment-page-sections-item _p-20">
		<h3 className="theme-payment-page-sections-item-title">
			<i><PersonIcon/></i>{intl.get("BOOK.TRAVELER")}
			<span className="Required"><span className="required">*</span>{intl.get("BOOK.REQUIRED_DESCRIPTION")}</span>
			</h3>
				<legend className="block-fieldset__legend">{props.index}. {intl.get("BOOK.TRAVELER")} <span className="paxes-fieldset__type">&nbsp;（{intl.get("TRAVELER." + props.type.toUpperCase())}）</span></legend>
					<div className="col-md-12 _p-15 ba-1" style={{marginBottom: "20px"}}>
						<div className="block-fieldset__body block-fieldset__body--alert-wrap">
							<div className="alert-box-inline alert-box-inline--info">
								<i className="fa fa-info-circle alert-box-inline__icn"></i>
								<p className="alert-box-inline__text">{intl.get("BOOK.NAME_REMIND")}</p>
							</div></div>
				   </div>

					<div className="row">
						<div className="col-sm-6 ">
							<div className="form-group">
								{/* <label className="control-label">{intl.get("COMMON.LAST_NAME")}<span className="required">*</span></label> */}

								{/* <input type="text" className="form-control" placeholder="" {...register(`traveler.${props.type}[${props.number}].lastName`, { required: true })} disabled={props.disabled} /> */}
								<TextField fullWidth
								error={errors && errors.traveler && errors.traveler[props.type][props.number].lastName ? true : false }
          label={intl.get("COMMON.LAST_NAME")+"*"}
		  disabled={props.disabled}
          defaultValue=""
		{...register(`traveler.${props.type}[${props.number}].lastName`, { required: true, maxLength: 25 })}
        />
								
							</div> </div>
			  
					<div className="col-sm-6">
						<div className="form-group">
							{/* <label className="control-label">{intl.get("COMMON.MIDDLE_NAME")}</label>
							<input type="text" className="form-control" placeholder="" {...register(`traveler.${props.type}[${props.number}].middleName`, { required: false })} disabled={props.disabled}/>
							<p className="help-block help-block-error"></p> */}
															<TextField fullWidth
          label={intl.get("COMMON.MIDDLE_NAME")}
		  disabled={props.disabled}
          defaultValue=""
		  {...register(`traveler.${props.type}[${props.number}].middleName`, { required: false, maxLength: 25 })}
        />
						</div></div>
					</div>
		
				<div className="row">
					<div className="col-sm-6">
				<div className="form-group">
				<TextField fullWidth
				error={errors && errors.traveler && errors.traveler[props.type][props.number].firstName ? true : false }
          label={intl.get("COMMON.FIRST_NAME")+"*"}
		  disabled={props.disabled}
          defaultValue=""
		  {...register(`traveler.${props.type}[${props.number}].firstName`, { required: true, maxLength: 25 })}
        />
					{/* <label className="control-label">{intl.get("COMMON.FIRST_NAME")}<span className="required">*</span></label>
					<input type="text" className="form-control" placeholder="" {...register(`traveler.${props.type}[${props.number}].firstName`, { required: true })} disabled={props.disabled}/>
					<p className="help-block help-block-error"></p> */}
				</div> </div>
					
					<div className="col-sm-6">
						<div className="form-group field-gender show-validation show-validation–inline field-passenger-0-ps_gender required has-success">
							<label className="control-label control-label--inline">{intl.get("COMMON.GENDER")}<span className="required">*</span></label>
							<div className="mb-3">
								<div className="custom-control custom-radio custom-control-inline">
								  <input id={`traveler${props.type}${props.number}SexMale`} className="custom-control-input" type="radio" value="M" defaultChecked={gender==1} disabled={props.disabled} {...register(`traveler.${props.type}[${props.number}].sex`, { required: true })} />
								  <label className="custom-control-label" htmlFor={`traveler${props.type}${props.number}SexMale`}>{intl.get("GENDER.MALE")}</label>
								</div>
								<div className="custom-control custom-radio custom-control-inline">
								  <input id={`traveler${props.type}${props.number}SexFemale`} className="custom-control-input" type="radio" value="F" defaultChecked={gender==2} disabled={props.disabled} {...register(`traveler.${props.type}[${props.number}].sex`, { required: true })}/>
								  <label className="custom-control-label" htmlFor={`traveler${props.type}${props.number}SexFemale`}>{intl.get("GENDER.FEMALE")}</label>
								</div>
							  </div>
								<p className="help-block help-block-error"></p>
						</div> 
	{/* <FormControl error={errors && errors.traveler && errors.traveler[props.type][props.number].sex ? true : false }>
      <FormLabel>{intl.get("COMMON.GENDER")}*</FormLabel>
	  <Controller
        name={`traveler.${props.type}[${props.number}].sex`}
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, onBlur, name, value, ref },
			fieldState: { invalid, isTouched, isDirty, error },
			formState }) => 
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
		onChange={onChange} // send value to hook form 
		onBlur={onBlur} // notify when input is touched/blur
		value={value} // input value
		// name={name} // send down the input name
		// inputref={ref} // send input ref, so we can focus on input when error appear
      >
        <FormControlLabel value="F" control={<Radio />} label={intl.get("GENDER.FEMALE")} />
        <FormControlLabel value="M" control={<Radio />} label={intl.get("GENDER.MALE")} />
      </RadioGroup>
    }/>
	</FormControl> */}
					</div>
				</div>
		
		
		<div className="row">			
			<div className="col-md-6">
			<div className="form-group">
				{/* <label className="control-label">{intl.get("COMMON.BIRTHDAY")}</label> */}
				<Controller
        name={`traveler.${props.type}[${props.number}].birthday`}
        control={control}
        rules={{ required: true }}
        render={({ field,
			fieldState: { invalid, isTouched, isDirty, error },
			formState }) => <LocalizationProvider dateAdapter={AdapterDateFns}>
		<DatePicker
		// {...field}
		onBlur={field.onBlur} // notify when input is touched
		inputRef={field.ref}
		disabled={disabledDatePicker}
		// onChange={onChange} // send value to hook form
				//   disableFuture
					minDate={minDate}
					maxDate={maxDate}
				  label={intl.get("COMMON.BIRTHDAY") + "*"}
				  openTo="year"
				  views={['year', 'month', 'day']}
				  value={dateValue}
				  onChange={(newValue) => {
					setDateValue(newValue);
					// console.log("date", newValue)

					field.onChange(newValue ? moment(newValue).format("MM/DD/YYYY"): "")
					// setValue(name, moment(newValue).format("MM/DD/YYYY"))
				  }}
				  renderInput={(params) => <TextField {...params} error={errors && errors.traveler && errors.traveler[props.type][props.number].birthday ? true : false } />}
				/>
				</LocalizationProvider>}
      />

				</div>
			  </div>
		</div>
		
		<div className="row">
						<div className="col-sm-6 ">
							<div className="form-group">
							<Autocomplete
							defaultValue={defaultCountry}
							disabled = {props.disabled}
    //   id="country-select-demo"
    //   sx={{ width: 300 }}
	onChange={(event, newValue) => {
		if(newValue){
			setValue(`traveler.${props.type}[${props.number}].nationality`, newValue.abbr)
			setValue(`traveler.${props.type}[${props.number}].idIssueCountry`, newValue.abbr)
		}else{
			setValue(`traveler.${props.type}[${props.number}].nationality`, "")
			setValue(`traveler.${props.type}[${props.number}].idIssueCountry`, "")
		}
	  }}
      options={countries}
      autoHighlight
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField

		// {...register(`traveler.${props.type}[${props.number}].nationality`)}
          {...params}
          label={intl.get("BOOK.TRAVELER_NATIONALITY")}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />


							{/* <TextField label="Nationality" variant="standard" {...register(`traveler.${props.type}[${props.number}].nationality`)}/> */}
								{/* <label className="control-label">Nationality</label> */}

								{/* <input type="text" className="form-control" placeholder="" {...register(`traveler.${props.type}[${props.number}].nationality`)}/> */}
								
								
							</div> </div>
			  
					<div className="col-sm-6">
						<div className="form-group">
						<FormControl sx={{ m: 0, minWidth: 120 }}>
  <InputLabel>{intl.get("BOOK.TRAVELER_ID_TYPE")}</InputLabel>
  <Select
//   labelId="demo-simple-select-label"
//   id="demo-simple-select"
    disabled={props.disabled}
    value={IDType}
    label="IDType"
    onChange={handleChange}

	// {...register(`traveler.${props.type}[${props.number}].IDType`, { required: false })}
  >
    <MenuItem value="P">{intl.get("BOOK.TRAVELER_ID_PASSPORT")}</MenuItem>
  </Select>
</FormControl>

						{/* <TextField label="ID Type" variant="standard" /> */}
							{/* <label className="control-label">ID Type</label>
							<input type="text" className="form-control" placeholder="" {...register(`traveler.${props.type}[${props.number}].IDType`, { required: false })}/>
							<p className="help-block help-block-error"></p> */}
						</div></div>
					</div>

					<div className="row">
						<div className="col-sm-6 ">
							<div className="form-group">
								{/* <label className="control-label">ID Number</label> */}

								{/* <input type="text" className="form-control" placeholder="" {...register(`traveler.${props.type}[${props.number}].IDNumber`)}/> */}
								
								<TextField fullWidth
          label={intl.get("BOOK.TRAVELER_ID_NUMBER")}
		  disabled={props.disabled}
          defaultValue=""
        //   helperText="Some important text"
		  {...register(`traveler.${props.type}[${props.number}].idNumber`, {maxLength: 25, pattern: /^[\d]+$/i})}
        />
							</div> </div>
			  
					<div className="col-sm-6">
						<div className="form-group">
						<Controller
        name={`traveler.${props.type}[${props.number}].idExpirationDate`}
        control={control}
        rules={{ required: false }}
        render={({ field,
			fieldState: { invalid, isTouched, isDirty, error },
			formState }) => <LocalizationProvider dateAdapter={AdapterDateFns}>
		<DatePicker
		// {...field}
		onBlur={field.onBlur} // notify when input is touched
		inputRef={field.ref}
		disabled={disabledDatePicker}
		// onChange={onChange} // send value to hook form
				//   disableFuture
					minDate={new Date()}
					// maxDate={maxDate}
				  label={intl.get("BOOK.TRAVELER_ID_EXPIRATION_DATE")}
				  openTo="year"
				  views={['year', 'month', 'day']}
				  value={IDExpirationDate}
				  onChange={(newValue) => {
					setIDExpirationDate(newValue);

					// console.log("date", newValue)

					field.onChange(newValue ? moment(newValue).format("MM/DD/YYYY"): "")
					// setValue(name, moment(newValue).format("MM/DD/YYYY"))
				  }}
				  renderInput={(params) => <TextField {...params} />}
				/>
				</LocalizationProvider>}
      />
						</div></div>
					</div>
		{/* <div className="accordion accordion-alternate" id="accordion">
		  <div className="card">
			<div className="card-header" id="heading1">
			  <h5 className="mb-0"> <a href="#" className="collapsed" data-toggle="collapse" data-target="#AddNewCard-1" aria-expanded="false" aria-controls="AddNewCard-1">{intl.get("BOOK.REWARD_TITLE")}</a> </h5>
			</div>
			  <div className="collapse theme-payment-page-sections-item-new-extend" id="AddNewCard-1">
				  <div className="theme-payment-page-form _mb-20">
					<div className="row row-col-gap" data-gutter="20">

						<div className="col-md-6 ">
							<label className="control-label">{intl.get("BOOK.REWARD")}
								<span className="optional-label" style={{color: "#949292", letterSpacing: "1px"}}>&nbsp;{intl.get("BOOK.OPTION")}</span>
							</label>
						<div className="theme-payment-page-form-item form-group">
						  <i className="fa fa-angle-down"></i>
						  <select className="form-control" defaultValue={""}>
								<option value="">选择奖励计划</option>
								  <option value="BR">长荣航空</option>
								<option value="CX">国泰航空</option> 
								<option value="LH">汉莎航空</option>
								<option value="QR">卡塔尔航空公司</option>
								<option value="EK">阿联酋航空</option>
								<option value="ET">埃塞俄比亚航空公司</option>
								<option value="BR">长荣航空</option>
								<option value="CX">国泰航空</option>  
						   </select>
						</div>
					  </div>


					  <div className="col-md-6 ">
						  <label className="control-label">{intl.get("BOOK.FREQUENT_FLYER_NUMBER")}
							<span className="optional-label" style={{color: "#949292", letterSpacing: "1px"}}>&nbsp;{intl.get("BOOK.OPTION")}</span>
							</label>
						<div className="theme-payment-page-form-item form-group">
						  <input className="form-control" type="text" placeholder=""/>
						</div>
					  </div>
					  <div className="col-md-6 ">
						  <label className="control-label">{intl.get("BOOK.KNOWN_PASSENGER_NUMBER")}
								<span className="optional-label" style={{color: "#949292", letterSpacing: "1px"}}>&nbsp;{intl.get("BOOK.OPTION")}</span>
							</label>
						<div className="theme-payment-page-form-item form-group">
						  <input className="form-control" type="text" placeholder=""/>
						</div>
					  </div>
					  <div className="col-md-6 ">
						  <label className="control-label">{intl.get("BOOK.REDRESS_NUMBER")}
								<span className="optional-label" style={{color: "#949292", letterSpacing: "1px"}}>&nbsp;{intl.get("BOOK.OPTION")}</span>
							</label>
						<div className="theme-payment-page-form-item form-group">
						  <input className="form-control" type="text" placeholder=""/>
						</div>
					  </div>
				   </div>
				  </div>
			   </div>
			  </div>
			</div> */}
			 </div>	
	)
}
