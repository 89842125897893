import React,{useState, useImperativeHandle, useRef } from 'react'
import Deck from '../../components/Deck'
import { useEffect } from 'react'
import { Box } from '@mui/system'
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Divider, Stack } from '@mui/material';
import intl from 'react-intl-universal';

// if (process.env.NODE_ENV === 'development'){
// 	require('../../mock/index')
// }

function removeCss(href) {
  var links = document.getElementsByTagName("link");
  for (var i = 0; i < links.length; i++) {
      var _href = links[i].href;
      if (links[i] && links[i].href && links[i].href.indexOf(href) != -1) {
          links[i].parentNode.removeChild(links[i]);
      }
  }
}


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


export default function Seatmaps(props) {
  console.log('seat maps', props);
    
    console.log('select seats', props.selectSeats);
    useEffect(()=>{
      removeCss('/Flight-Hotel/css/styles.css')
    },[])



  return (
    <Box>
            <div className='deck-title' style={{border: '2px solid #222', backgroundColor: '#EEEEEE'}}>
    <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
      >
        <Item>
          <div className='seat' style={{
    // left: `${props.y*3}em`, 
    // top: `${props.x*3}em`,
    width: "2.8em",
    height: "2.8em",
    backgroundColor: '#499167',
    color: "white",
    cursor: "pointer"
  }}>
          </div>
          <span>{intl.get('SEATMAP.AVAILABLE')}</span>
        </Item>
        <Item><div className='seat' style={{
    // left: `${props.y*3}em`, 
    // top: `${props.x*3}em`,
    width: "2.8em",
    height: "2.8em",
    backgroundColor: '#FE5F55',
    color: "white",
    cursor: "pointer"
  }}>
          </div>
          <span>{intl.get('SEATMAP.UNAVAILABLE')}</span></Item>
        <Item><div className='seat' style={{
    // left: `${props.y*3}em`, 
    // top: `${props.x*3}em`,
    width: "2.8em",
    height: "2.8em",
    backgroundColor: '#ffc107',
    color: "white",
    cursor: "pointer"
  }}>
          </div>
          <span>{intl.get('SEATMAP.SELECTED')}</span></Item>
          <Item><div className='seat' style={{
    // left: `${props.y*3}em`, 
    // top: `${props.x*3}em`,
    width: "2.8em",
    height: "2.8em",
    backgroundColor: '#1976d2',
    color: "white",
    cursor: "pointer"
  }}>
          </div>
          <span>{intl.get('SEATMAP.ASSIGNED')}</span></Item>
      </Stack>
      <Divider/>
      <Stack direction="row" spacing={2}>
            {props.selectSeats.map((seat)=>
              <Item key={seat.number}>
                <div className='seat' style={{
                    backgroundColor:"#499167",
    color: "white",
    width: "2.8em",
    height: "2.8em",
    cursor: "pointer"
  }}>
              <p style={{fontSize: '0.8em', margin: 'auto'}}>{seat.number}</p>
              <small>{"$"+seat.travelerPricing[0].price.total}</small>
  </div>

            </Item>
            )}
</Stack>
    </div>

          <Deck deck={props.deck} selectSeats={props.selectSeats} addSeat={props.addSeat} removeSeat={props.removeSeat} max={props.travelers.length - props.deck.selectedSeatNumbers.length}/>
      </Box>
  )
}
