import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import intl from 'react-intl-universal';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import PersistentDrawerLeft from '../../components/PersistentDrawerLeft';
import CloseIcon from '@mui/icons-material/Close';

import { Dialog, DialogTitle, DialogContent, Divider, AppBar, Toolbar, IconButton } from '@mui/material';
import { useState } from 'react';
import MyTabs from '../MyTabs';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function BasicCard(props) {
  const [select, setSelect] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [prebookingInfo, setPrebookingInfo] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  console.log('basic card', props)
  let brandNames = []
  if(props.routing.brand && props.routing.brand.brands){
    brandNames = props.routing.brand.brands.map((brand=>brand.name));
  }
  console.log('brand names', brandNames)

  let rules = []
  for(let rule in props.routing.rules){
    rules.push(`${rule}: ${props.routing.rules[rule]}`)
  }

  const handleClick = ()=>{
    if(select){
      props.chooseSell(null);
    }else{
      props.chooseSell(props.routing);
    }
    setSelect(!select)
  }

  const handleShowRules=()=>{
    let params = {
      adults: props.travelers.adults,
      children: props.travelers.children,
      routing: JSON.stringify(props.routing),
      cid: props.routing.cid,
      isPrebooking: true
    }

    setOpen(true)
    if(prebookingInfo == null){
    axios.post('api/book', params)
		.then(function (res) {
		  let json = JSON.parse(res.data);
		  console.log("prebooking", json)
		  if("APIError" in json){
			  for(let error of json.APIError.errors){
				  // console.log(error.errorId, error.message);
				  // setMessage(error.message);

				  alert(error.message)
			  }			  
		  }else if("airTicketPriceChangedResponse" in json){
			  alert("Price changed, please re-search.");
		  }else if("airTicketRuleResponse" in json){
			  setPrebookingInfo(json.airTicketRuleResponse)
        setDialogOpen(true)
		  }
		})
		.catch(function (error) {
		  console.error(error, typeof(error));
		  alert(error);
		}).then(function () {
		  setOpen(false)
		});
    }else{
      setOpen(false)
      setDialogOpen(true)
    }
  }

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Word of the Day
        </Typography>
        <Typography variant="h5" component="div">
          be{bull}nev{bull}o{bull}lent
        </Typography> */}
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
        {brandNames.join(',')}
        </Typography>
        <Typography variant="body2">
          {intl.get("BOOK.TOTAL")}: ${(props.routing.adultBasePrice + props.routing.adultTax).toFixed(2)}
          <br />
          {/* {'"a benevolent smile"'} */}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleClick}>{select ? intl.get('BOOK.CABIN_UNSELECT') : intl.get('BOOK.CABIN_SELECT')}</Button>

        <Button size="small" onClick={handleShowRules}>{intl.get('BOOK.SHOW_FARE_RULES')}</Button>
      </CardActions>
      <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={open}
  onClick={()=>setOpen(false)}
>
  <CircularProgress color="inherit" />
</Backdrop>
<Dialog
	  	fullScreen
        open={dialogOpen}
        onClose={()=>{setDialogOpen(false)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
<AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={()=>setDialogOpen(false)}
              aria-label="close"
            >
              <CloseIcon/>
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
			{intl.get('BOOK.FARE_RULES')}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
			{/* <PersistentDrawerLeft handleClose={()=>setDialogOpen(false)} fareRules={prebookingInfo} brandRules={props.routing.brand || null}/> */}
      <MyTabs fareRules={prebookingInfo} brandRules={props.routing.brand || null}/>
        </DialogContent>
      </Dialog>
    </Card>
  );
}