import React, { useState } from 'react'
import { useEffect } from 'react'

export default function Demo() {
    const [gener, setGender] = useState(0)
    useEffect(()=>{
        setGender(1)
    },[])
  return (
    <div>
        <input type={'radio'} name='name' defaultChecked={gener == 1} value={'male'}/>male
        <input type={'radio'} name='name' defaultChecked={gener != 1} value={'female'}/>female
    </div>
  )
}
