import React,{useState, useImperativeHandle, useRef } from 'react'
import Deck from '../../components/Deck'
// import { seatSource } from '../../data/source'
import axios from 'axios'
import { AppBar, LinearProgress, TextField } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/system'
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import intl from 'react-intl-universal';
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useForm } from "react-hook-form";
import { Controller  } from "react-hook-form";
import moment from 'moment';
// import { createRef } from 'react'
// if (process.env.NODE_ENV === 'development'){
// 	require('../../mock/index')
// }

function removeCss(href) {
  var links = document.getElementsByTagName("link");
  for (var i = 0; i < links.length; i++) {
      var _href = links[i].href;
      if (links[i] && links[i].href && links[i].href.indexOf(href) != -1) {
          links[i].parentNode.removeChild(links[i]);
      }
  }
}


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


export default function Seatmap(props) {
  // console.log('seat map', props);
    const location = useLocation()
    const [selectSeats, setSelectedSeats] = useState([])
    const [loading, setLoading] = useState(false)
    const [decks, setDecks] = useState([])
    const [seatmaps, setSeatmaps] = useState(null)
    const [expirationDate, setExpirationDate] = useState(null)
    const [showPayment, setShowPayment] = useState(false)
    const { register, getValues, control, handleSubmit } = useForm();
    // const watchAllFields = watch();
    // const submitRef = useRef()
    // const { control } = useFormContext();

    console.log('select', selectSeats)
    useEffect(()=>{
      removeCss('/Flight-Hotel/css/styles.css')
      handleRequest()
    },[])

    // useEffect(() => {
    //   // watch((value, { name, type }) => console.log(value, name, type));
    //   watch(()=>{
    //     // console.log('values', getValues())
    //     props.changeValue(getValues())
    //   })
      
    // }, [watch]);

    const onSubmit = data => {
      console.log('submit', data);
    }

    const findItem =(items)=>{
      console.log('find items', items)
      if(items.length>0){
        for(let item of items){
          for(let seat of item.seats){
            if(seat.travelerPricing[0].price.total>0){
              return true
            }
          }
        }
      }
    
      return false
    }

    const addSeat=(id, seat)=>{
        // console.log('add seat', id, seat)
        let index = selectSeats.findIndex(item=>item.id === id)
        if(index < 0){
          setSelectedSeats([...selectSeats, {id: id, seats: [seat]}])
          console.log('find items', findItem([...selectSeats, {id: id, seats: [seat]}]));
          setShowPayment(findItem([...selectSeats, {id: id, seats: [seat]}]))
          props.changeSeatmaps({seatmaps, selectedSeatmaps:[...selectSeats, {id: id, seats: [seat]}]})
        }else{
          selectSeats[index].seats = [...selectSeats[index].seats, seat]
          setSelectedSeats([...selectSeats])
          props.changeSeatmaps({seatmaps, selectedSeatmaps:[...selectSeats]})
          console.log('find items', findItem([...selectSeats, {id: id, seats: [seat]}]));
          setShowPayment(findItem([...selectSeats, {id: id, seats: [seat]}]))
        }
    }

    const removeSeat=(id, seatNumber)=>{
        // console.log('remove', id, seatNumber);
        let index = selectSeats.findIndex(item=>item.id === id)
        if(index >= 0){
          selectSeats[index].seats = selectSeats[index].seats.filter(item=>item.number !== seatNumber)
          setSelectedSeats([...selectSeats])
          props.changeSeatmaps({seatmaps, selectedSeatmaps:[...selectSeats]})

          console.log('find items', findItem([...selectSeats]));
          setShowPayment(findItem([...selectSeats]))
        }
    }

    useImperativeHandle(props.onRef, ()=>{
      return{
        func: getData
      };
    })

    const getData=()=>{
      // console.log('demo', );
      // submitRef.current.click()
      return getValues()
    }

    const handleRequest=()=>{
        setLoading(true)

        axios.interceptors.response.use(function (response) {
          let config = response.config;
          // 2xx 范围内的状态码都会触发该函数。
          // 对响应数据做点什么
          if (!config || !config.retry) return response;
      
          // 设置变量以跟踪重试次数
          config.__retryCount = config.__retryCount || 0;
      
          // 判断是否超过总重试次数
          if (config.__retryCount >= config.retry) {
              // 返回错误并退出自动重试
              return response;
          }
      
          // 增加重试次数
          config.__retryCount += 1;
      
          //打印当前重试次数
          console.log(config.url +' retry ' + config.__retryCount + 'times');
      
      //     // 创建新的Promise
      if(response.data.indexOf('*UNABLE TO PROCESS*')>=0){
          var backoff = new Promise(function (resolve) {
              setTimeout(function () {
                  resolve();
              }, config.retryDelay || 1);
          });
      
          // 返回重试请求
          return backoff.then(function () {
              return axios(config);
          });
        }else{
          return response;
        }
        }, function (error) {
          // 超出 2xx 范围的状态码都会触发该函数。
          // 对响应错误做点什么
          return Promise.reject(error);
        });
        
        axios.get(`api/seatmaps?cid=${props.cid}&pcc=${props.pcc}&pnr=${props.pnr}`, {
          retry: 1,
          retryDelay: 5000,
        })
        // axios.get(`api/seatmaps?cid=flygndc&pcc=1ED5&pnr=37JF0D`)
        .then(function (res) {
          const json = JSON.parse(res.data)
          console.log('seat map json', json);
          setSeatmaps(json)
          if("hop2WsError" in json){
            alert(json.hop2WsError.errors[0].message)
            return
          }
          let procDecks = []
          let seatNum = []
          for(let flight of json){
            for(let seatMapDetail of flight.SeatMapDetails){
              for(let airRow of seatMapDetail.airRows){     
                for(let airSeat of airRow.airSeats){
                  let alphabet  = airSeat.seatNumber.substring(airSeat.seatNumber.length-1)
                  if(seatNum.indexOf(alphabet) < 0){
                    seatNum.push(alphabet)
                  }
                }
              }
            }
          }
  
          seatNum.sort()
  
          // let seatNums = seatNum.map((item, index)=> ({item: index}))
          // console.log(seatNums)
  
          for(let flight of json){
            for(let seatMapDetail of flight.SeatMapDetails){
              for(let airRow of seatMapDetail.airRows){
                for(let i = 0; i< airRow.airSeats.length; i++){
                  let alphabet  = airRow.airSeats[i].seatNumber.substring(airRow.airSeats[i].seatNumber.length-1)
                  if(i>0){
                    if(airRow.airSeats[i].seatCharacteristics && airRow.airSeats[i].seatCharacteristics.split(',').indexOf("A")>=0
                    && airRow.airSeats[i-1].seatCharacteristics && airRow.airSeats[i-1].seatCharacteristics.split(',').indexOf("A") >= 0){
                      if(seatNum.indexOf(alphabet)-1 >= 0 && seatNum[seatNum.indexOf(alphabet) - 1] != 'x')
                      seatNum.splice(seatNum.indexOf(alphabet), 0, 'x')
                    }
                  }
                }
              }
            }
          }
  
  
          for(let flight of json){
            let deck = {"deckConfiguration": {
              "width": 7,
              "length": 30,
              "startseatRow": 14,
              "endSeatRow": 38,
              "startWingsRow": 4,
              "endWingsRow": 14,
              "startWingsX": 1,
              "endWingsX": 11,
              "exitRowsX": [
                0,
                0
              ]
            },"facilities": [], seats:[], id: flight.CatalogOfferingIdentifierValue, selectedSeatNumbers:[]}

            let selectedSeatNumbers = []
            for(let traveler of flight.TravelerIdentifiers){
              if(traveler.AreadySelectedSeatNumber){
                deck.selectedSeatNumbers.push(traveler.AreadySelectedSeatNumber)
              }
            }

            // console.log('flight', flight)
            let x = 1;
            for(let i = 0;i< flight.SeatMapDetails.length;i++){
              if(i>0){
                x += parseInt(flight.SeatMapDetails[i].StartRowNumber) - parseInt(flight.SeatMapDetails[i-1].EndRowNumber)-1
      
                  // let diffRows = parseInt(flight.SeatMapDetails[i].StartRowNumber) - parseInt(flight.SeatMapDetails[i-1].EndRowNumber);
                  // if(diffRows > 1){
                  //   x+=diffRows-1
                  // }
                }
    
              for(let airRow of flight.SeatMapDetails[i].airRows){
                for(let j=0; j<airRow.airSeats.length; j++){
                  let selected = false;
                  if(deck.selectedSeatNumbers.indexOf(airRow.airSeats[j].seatNumber) >= 0){
                    selected = true
                  }
                  let seat = {
                    "cabin": "ECONOMY",
                    "number": airRow.airSeats[j].seatNumber,
                    "characteristicsCodes": (airRow.airSeats[j].seatCharacteristics ? airRow.airSeats[j].seatCharacteristics.split(',') : []),
                    "travelerPricing": [
                      {
                        "id": "1",
                        "seatAvailabilityStatus": (airRow.airSeats[j].seatAvailabilityStatus ? airRow.airSeats[j].seatAvailabilityStatus.toUpperCase(): airRow.airSeats[j].seatAvailabilityStatus),
                        "price": {
                          "currency": airRow.airSeats[j].CurrencyCode,
                          "total": airRow.airSeats[j].TotalPrice
                        }
                      }
                    ],
                    "coordinates": {
                      "x": x,
                      // "y": airRow.airSeats[j].seatNumber.substring(airRow.airSeats[j].seatNumber.length-1).charCodeAt()-65
                      "y": seatNum.indexOf(airRow.airSeats[j].seatNumber.substring(airRow.airSeats[j].seatNumber.length-1))
                      // "y": y
                    },
                    "selected": selected
                  }
  
                  // y++
  
                  // console.log('seatCharacteristics', airRow.airSeats[j].seatCharacteristics)
                  // if(j > 0){
                  //   // console.log('seat', seat, airRow.airSeats[j].seatCharacteristics, airRow.airSeats[j].seatCharacteristics.split(',').indexOf("A"),
                  //   // airRow.airSeats[j-1].seatCharacteristics, airRow.airSeats[j-1].seatCharacteristics.split(',').indexOf("A"))
                  //   if(airRow.airSeats[j].seatCharacteristics && airRow.airSeats[j].seatCharacteristics.split(',').indexOf("A")>=0
                  //   && airRow.airSeats[j-1].seatCharacteristics && airRow.airSeats[j-1].seatCharacteristics.split(',').indexOf("A") >= 0){
                  //     seat.coordinates.y = seat.coordinates.y + 1
                  //   }
                  // }
                  
                  // console.log(seat)
                  deck.seats.push(seat)
                }
                
                x++
                }           
            }
    
            deck.deckConfiguration.width = Math.max.apply(Math, deck.seats.map(item=>item.coordinates.y)) + 1
            deck.deckConfiguration.length = deck.seats[deck.seats.length-1].coordinates.x
            deck.segments = flight.FlightSegments
            deck.travelers = flight.TravelerIdentifiers
            procDecks.push(deck)
            // break
          }
          
          setDecks(procDecks)
        })
        .catch(function (error) {
          console.error(error);
          alert(error)
        }).then(function () {
          setLoading(false)
        });

    //   axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
    //     var config = err.config;
    //     // 如果配置不存在或未设置重试选项，则拒绝
    //     if (!config || !config.retry) return Promise.reject(err);
    
    //     // 设置变量以跟踪重试次数
    //     config.__retryCount = config.__retryCount || 0;
    
    //     // 判断是否超过总重试次数
    //     if (config.__retryCount >= config.retry) {
    //         // 返回错误并退出自动重试
    //         return Promise.reject(err);
    //     }
    
    //     // 增加重试次数
    //     config.__retryCount += 1;
    
    //     //打印当前重试次数
    //     console.log(config.url +' 自动重试第' + config.__retryCount + '次');
    
    //     // 创建新的Promise
    //     var backoff = new Promise(function (resolve) {
    //         setTimeout(function () {
    //             resolve();
    //         }, config.retryDelay || 1);
    //     });
    
    //     // 返回重试请求
    //     return backoff.then(function () {
    //         return axios(config);
    //     });
    // });

    }
  return (
    <Box>
    {(loading || props.submiting) && <AppBar position="fixed"><LinearProgress /></AppBar>}
    {decks.map((deck, i) => (
          <Accordion key={deck.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Typography>{i+1}. {`${deck.segments[0].origin}-${deck.segments[0].destination} ${deck.segments[0].marketingAirline}${deck.segments[0].flightNumber}`}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{padding: 'inherit', zIndex: 9}}>
          <Stack direction="row" spacing={2}>
            {selectSeats.find(item=>item.id == deck.id) && selectSeats.find(item=>item.id == deck.id).seats.map((seat)=>
              <Item key={seat.number}>
              <p>{seat.number}</p>
              <p>{"$"+seat.travelerPricing[0].price.total}</p>
            </Item>
            )}
</Stack>
          <Deck deck={deck} key={deck.id} selectSeats={selectSeats.find(item=>item.id==deck.id) ? selectSeats.find(item=>item.id==deck.id).seats : []} addSeat={addSeat} removeSeat={removeSeat} max={props.passengers.length - deck.selectedSeatNumbers.length}/>
          </AccordionDetails>
        </Accordion>
      ))}
      {/* <h1>DEMO:{selectSeats.length}{findItem(selectSeats) ? "YES" : "NO"}</h1> */}
      {/* {selectSeats.length > 0 && selectSeats.seats.indexOf(item=>item.travelerPricing[0].price.total>0)} */}
      {showPayment &&
<form onSubmit={handleSubmit(onSubmit)}>
<div className="row row-col-gap" style={{paddingTop: "30px"}} data-gutter="20">
           {/* <div className="col-md-12" style={{marginBottom: "20px"}}>
            <ul className="theme-payment-page-card-list">
              <li>
                <img src="/Flight-Hotel/panelAirR/images/footer-payment-social.svg" alt="Image Alternative text" title="Image Title"/>
              </li>
             </ul>
          </div> */}
<div className="col-md-6 " style={{paddingBottom: "0px"}}>
                    <label className="control-label">{intl.get("PAYMENT.CARDHOLDER_LAST_NAME")}</label>
                    <input type="text" className="form-control" placeholder="" {...register("lastName", { required: true})} />
                    <p className="help-block help-block-error"></p>
         </div>
<div className="col-md-6 " style={{paddingBottom: "0px"}}>
                    <label className="control-label">{intl.get("PAYMENT.CARDHOLDER_MIDDLE_NAME")}</label>
                    <input type="text" className="form-control" placeholder="" {...register("middleName", { required: false })}/>
                    <p className="help-block help-block-error"></p>
         </div>
<div className="col-md-6 " style={{paddingBottom: "0px"}}>
                    <label className="control-label">{intl.get("PAYMENT.CARDHOLDER_FIRST_NAME")}</label>
                    <input type="text" className="form-control" placeholder="" {...register("firstName", { required: true})}/>
                    <p className="help-block help-block-error"></p>
         </div>
        <div className="col-md-6" style={{paddingBottom: "0px"}}>
                <label className="control-label">{intl.get("PAYMENT.CARD_NUMBER")}</label>
                <input type="text" className="form-control" placeholder="xxxx xxxx xxxx xxxx" {...register("cardNumber", { required: true})}/>
                <p className="help-block help-block-error"></p>
          </div>
          


            <div className="col-md-3">
              <div className="form-group">
              <Controller
        name={'expirationDate'}
        control={control}
        rules={{ required: false }}
        render={({ field,
			fieldState: { invalid, isTouched, isDirty, error },
			formState }) => <LocalizationProvider dateAdapter={AdapterDateFns}>
		<DatePicker
		// {...field}
		// onBlur={field.onBlur} // notify when input is touched
		inputRef={field.ref}
		// disabled={disabledDatePicker}
		// onChange={onChange} // send value to hook form
				//   disableFuture
					minDate={new Date()}
					// maxDate={maxDate}
				  label={intl.get("PAYMENT.EXPIRY_DATE")}
				  openTo="year"
				  views={['year', 'month']}
				  value={expirationDate}
				  onChange={(newValue) => {
					setExpirationDate(newValue);
					field.onChange(newValue ? moment(newValue).format("MM/YYYY"): "")
				  }}
				  renderInput={(params) => <TextField sx={{marginTop: "1.6em"}} {...params} />}
				/>
				</LocalizationProvider>}/>
               {/* <label className="control-label">{intl.get("PAYMENT.EXPIRY_DATE")}</label> */}
                {/* <input id="expiryDate" type="text" className="form-control" data-bv-field="expiryDate" required="" placeholder="MM/YY"/> */}
              </div>
            </div>
            <div className="col-md-3">
               <div className="form-group">
               <TextField type={'password'} sx={{marginTop: "1.6em"}} label={intl.get("PAYMENT.VERIFICATION_CODE")} variant="outlined" {...register("verificationCode", { required: true})} />
                {/* <label className="control-label">{intl.get("PAYMENT.VERIFICATION_CODE")}</label> */}
                {/* <input id="cvvNumber" type="password" className="form-control" data-bv-field="cvvnumber" required="" placeholder="xxx"/> */}
              </div>
            </div>
            </div>
            {/* <input type='submit' ref={submitRef}></input> */}
            </form>}
      </Box>
  )
}
