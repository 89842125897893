import Seat from '../Seat';
import Facility from '../Facility';
import Exit from '../Exit';
import Wing from '../Wing'
// import './index.css'


const displaySeats = (id, seatList, selectSeats, addSeat, removeSeat, max) => {
  // console.log('display seats', id, seatList)
  return <div>{seatList.map((seat) => 
  <Seat key={seat.number} id={id} number={seat.number} x={seat.coordinates.x} y={seat.coordinates.y} 
  availability={seat.travelerPricing[0].seatAvailabilityStatus} price={seat.travelerPricing[0].price.total}
  selectSeats={selectSeats} addSeat={addSeat} removeSeat={removeSeat} data={seat} max={max} selected={seat.selected}
  />
  )}</div>
}

const displayFacilities = (facilityList) => {
return <div>{facilityList.map((facility, i) => 
<Facility key={i} code={facility.code} x={facility.coordinates.x} y={facility.coordinates.y} />
)}</div>
}

const displayExits = (exitRows) => {
  return <div>{exitRows.map((row) => <Exit key={row} row={row} />)}</div>
}

const displayWings = (start, end) => {
  return <><Wing orientation="left" start={start} end={end} />
  <Wing orientation="right" start={start} end={end} />
  </>
}


const Deck = (props) => {
  const width = props.deck.deckConfiguration.width;
  const length = props.deck.deckConfiguration.length;
  const seatList = props.deck.seats;
  const facilities = props.deck.facilities;
  const wingStart = props.deck.deckConfiguration.startWingsX;
  const wingEnd = props.deck.deckConfiguration.endWingsX;
  const exitRows = props.deck.deckConfiguration.exitRowsX;

  
  return (
    <div className='deck' style={{width:`${width*3.2}em`, height:`${length*3.2}em`, border: '2px solid #222', position: 'absolute', 
    left: '30vw', backgroundColor: '#EEEEEE'}}>
      {/* {displayWings(wingStart, wingEnd)} */}
      {displaySeats(props.deck.id, seatList, props.selectSeats, props.addSeat, props.removeSeat, props.max)}
      {displayFacilities(facilities)}
      {/* {displayExits(exitRows)} */}
    </div>
  )
}

export default Deck;