import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Alert } from '@mui/material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function MyTabs(props) {
    const [value, setValue] = React.useState(0);

    const rules = []
    if(props.fareRules){
      // if(props.fareRules.rules && !props.fareRules.fullNdcRulls){
      //     let rule = {name: 'Fare', messages: []}
      //     for(let r in props.fareRules.rules){
      //         rule.messages.push(`${r}: ${props.fareRules.rules[r]}`)
      //     }
  
      //     rules.push(rule)
      // }
  
      if(props.fareRules.fullNdcRulls){
          for(let key in props.fareRules.fullNdcRulls){
              let rule = {name: key, messages: []}
              if(key == 'penalty' && props.fareRules.fullNdcRulls[key]){
                  for(let penalty of props.fareRules.fullNdcRulls[key]){
                      for(let r of penalty.rules){
                          rule.messages.push(`${r.Name}: ${r.Value}`)
                      }
                  }
              }
  
              if(key == 'baggage' && props.fareRules.fullNdcRulls[key]){
                  for(let baggageKey in props.fareRules.fullNdcRulls[key]){
                      if(props.fareRules.fullNdcRulls[key][baggageKey]){
                          for(let seg of props.fareRules.fullNdcRulls[key][baggageKey]){
                              let measurementMsgs = []
                              if(seg.measurement){
                                  for(let measurement of seg.measurement){
                                      measurementMsgs.push(`${measurement.MeasurementType}: ${measurement.Value}${measurement.Unit}`)
                                  }
                              }
  
                              if(seg.quantity){
                                rule.messages.push(`${baggageKey}: ${seg.type}, ${seg.quantity} quantity${measurementMsgs.length > 0 ?  ", " + measurementMsgs.join("; "): ""}`)
                              }
  
                          }
                          
                      }
                  }
              }
  
              rules.push(rule)
          }
      }
    }
  
    if(props.brandRules && props.brandRules.brands){
      let rule = {name: 'Attributes', messages: []}
      for(let brand of props.brandRules.brands){
          if(brand.attrs){
              for(let attr of brand.attrs){
                  rule.messages.push(`${attr.name}: ${attr.value}`)
              }
          }
      }
  
      rules.push(rule)
    }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable"
  scrollButtons="auto">
            {rules.map((rule, index)=> <Tab key={rule.name} label={rule.name} {...a11yProps(index)} />)}
        </Tabs>
      </Box>
      {rules.map((rule, index)=>
            <TabPanel key={rule.name} value={value} index={index}>
            {rule.messages && rule.messages.length > 0 ?   
            rule.messages.map((message, i)=>
            <Typography key={rule.name + i} paragraph>
            {message}
      </Typography>
            ) : <Alert severity="warning">No Result</Alert>}
          </TabPanel>
      )}
    </Box>
  );
}