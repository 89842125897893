import React from 'react'
import { Box, CircularProgress } from '@mui/material';

export default function ProgressBox() {
  return (
    <Box sx={{ height: 200, width:200, position: "absolute", left: "50%", top: "50%", marginLeft: "-100px", marginTop: "-100px" }}>
    <CircularProgress color='warning' size={120}/>
  </Box>
  )
}
