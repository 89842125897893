import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import loadCss from "../../common/loadCss";
import OrderSummary from '../../components/OrderSummary';
import TripDetail from '../../components/TripDetail';
import {Button} from '@mui/material';
import intl from 'react-intl-universal';
import style from './index.module.css'
import moment from 'moment';
import BasicSpeedDial from '../../components/BasicSpeedDial';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Seatmap from '../Seatmap';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { createRef } from 'react';
import ButtonAppBar from '../../components/ButtonAppBar';

function ToUpper(s){
  if(s){
    return s.replace(/^\S/, s => s.toUpperCase())
  }

  return s;
}

function Traveler(props){
    const {passenger}= props

    // console.log("traveler", props);
    return(
        <div className="col-md-12 theme-payment-success-box">
        <ul className="theme-payment-success-summary">
          <li><i className="fa fa-user-check _mr-10"></i><b>{`${passenger.lastName}/${ToUpper(passenger.firstName)}${!passenger.middleName ? "": " " + ToUpper(passenger.middleName)}`}</b></li>
          <li><svg className="_mr-10 font-weight-900" width="16" height="14" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.333344 4.33333V0.666667C0.333344 0.489856 0.403581 0.320286 0.528606 0.195262C0.65363 0.0702379 0.823199 0 1.00001 0H13C13.1768 0 13.3464 0.0702379 13.4714 0.195262C13.5964 0.320286 13.6667 0.489856 13.6667 0.666667V4.33333C13.2246 4.33333 12.8007 4.50893 12.4882 4.82149C12.1756 5.13405 12 5.55797 12 6C12 6.44203 12.1756 6.86595 12.4882 7.17851C12.8007 7.49107 13.2246 7.66667 13.6667 7.66667V11.3333C13.6667 11.5101 13.5964 11.6797 13.4714 11.8047C13.3464 11.9298 13.1768 12 13 12H1.00001C0.823199 12 0.65363 11.9298 0.528606 11.8047C0.403581 11.6797 0.333344 11.5101 0.333344 11.3333V7.66667C0.775371 7.66667 1.19929 7.49107 1.51185 7.17851C1.82442 6.86595 2.00001 6.44203 2.00001 6C2.00001 5.55797 1.82442 5.13405 1.51185 4.82149C1.19929 4.50893 0.775371 4.33333 0.333344 4.33333ZM1.66668 3.312C2.16748 3.5602 2.58899 3.94337 2.88368 4.41831C3.17837 4.89324 3.33451 5.44107 3.33451 6C3.33451 6.55894 3.17837 7.10676 2.88368 7.58169C2.58899 8.05663 2.16748 8.4398 1.66668 8.688V10.6667H12.3333V8.688C11.8325 8.4398 11.411 8.05663 11.1163 7.58169C10.8217 7.10676 10.6655 6.55894 10.6655 6C10.6655 5.44107 10.8217 4.89324 11.1163 4.41831C11.411 3.94337 11.8325 3.5602 12.3333 3.312V1.33333H1.66668V3.312ZM5.00001 4.66667C5.00001 4.29848 5.29849 4 5.66668 4H8.33334C8.70153 4 9.00001 4.29848 9.00001 4.66667C9.00001 5.03486 8.70153 5.33333 8.33334 5.33333H5.66668C5.29849 5.33333 5.00001 5.03486 5.00001 4.66667ZM5.00001 7.33333C5.00001 6.96514 5.29849 6.66667 5.66668 6.66667H8.33334C8.70153 6.66667 9.00001 6.96514 9.00001 7.33333C9.00001 7.70152 8.70153 8 8.33334 8H5.66668C5.29849 8 5.00001 7.70152 5.00001 7.33333Z" fill="#808080"></path>
                  </svg><b>{intl.get("SUCCESS.TICKETNUMBER")}</b><span>{ passenger.tktnumber ? passenger.tktnumber : intl.get("SUCCESS.TO_DO")}</span>
            </li>
              <li><i className="fa fa-venus-mars _mr-10"></i><b>{intl.get("COMMON.GENDER")}</b><span>{intl.get("GENDER." + (passenger.gender == "M" ? "MALE" : "FEMALE"))}</span></li>
              <li><i className="fa fa-calendar-alt _mr-10"></i><b>{intl.get("COMMON.BIRTHDAY")}</b><span>{passenger.birthday}</span></li>
              {/* <li><i className="fa fa-globe-americas _mr-10"></i><b>国籍</b><span>亚美尼亚</span></li> */}
         </ul>
       </div>	
    )
}

function PaymentBox(props){
	return(
		<>
		{intl.get('SUCCESS.PAYMENT_TITLE')}
		{intl.get('PRICE', {currency: intl.get('CURRENCY.'+props.currency+'.SYMBOL'), 
	   price: props.price})}
	  {/* {intl.getHTML("SUCCESS.PAYMENT_LINK", {link: 'payment/' + props.paymentLink})} */}
	  {intl.get('SUCCESS.PAYMENT_LINK_BEFORE')}
	  <Link to={"/payment/" + props.paymentLink}>{intl.get('SUCCESS.PAYMENT_LINK_TEXT')}</Link>
	  {intl.get('SUCCESS.PAYMENT_LINK_AFTER')}
	  </>
	)
}

// function OtherBox(props){
// 	if(props.orderStatus == 2){
// 		return(
// 			<>
// 			{intl.get("SUCCESS.DESCRIPTION")}
// 			{/* {intl.getHTML('SUCCESS.ORDER_LINK', 
// 				{link: 'order?p=' + props.openid})} */}
// 				<Link to={"/order?p=" + props.openid}>
// 					{intl.getHTML("SUCCESS.ORDER_TEXT")}
// 				</Link>
// 				</>
// 		)

// 	}else{
// 	return(
// 		<>
// 		{intl.get("SUCCESS." + parseStatus(props.orderStatus))}
// 		<Link to={"/order?p=" + props.openid}>
// 					{intl.getHTML("SUCCESS.ORDER_TEXT")}
// 				</Link>
// 				{/* {intl.getHTML('SUCCESS.ORDER_LINK', 
// 				{link: 'order?p=' + props.openid})} */}
// 				</>
// 	)
// 	}
// }

function OtherBox(props){
	return(
		<>
		{props.orderStatus == 2 ? intl.get("SUCCESS.DESCRIPTION") : intl.get("SUCCESS." + parseStatus(props.orderStatus))}
		<Link to={"/order?p=" + props.openid}>
			{intl.getHTML("SUCCESS.ORDER_TEXT")}
		</Link>
		</>
	)
}

function parseStatus(orderStatus){
	switch(orderStatus){
		case 0:
			return "NEW"
		case 1:
			return "PAYMENTING"
		case 2:
			return "PROCESSING"
		case 3:
			return "ISSUED"
		case 4:
			return "CANCELLED"
	}
}

const MySeatmap = (props) => {
	// console.log('my seatmap', props)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const[open, setOpen] = useState(false)
	const [seatmapsInfo, setSeatmapsInfo] = useState(null)
	const [submiting, setSubmiting] = useState(false)
	// const [payment, setPayment] = useState(null)
	// useEffect(()=>{
	// 	enqueueSnackbar('I love hooks', { variant:'success' });
	// },[])
	const handleSeatmaps = ()=>{
		let payment = childRef.current.func()
		// console.log('payment', payment);
		// return;
		console.log('seat maps info', seatmapsInfo);

		if(seatmapsInfo == null || seatmapsInfo.selectedSeatmaps == null || seatmapsInfo.selectedSeatmaps.length == 0
			|| seatmapsInfo.selectedSeatmaps.findIndex(item=>item.seats.length>0)<0){
			alert('Not selected')
			return
		}

		setSubmiting(true)
		let newPayment = null;
		if(payment){
			newPayment = {lastName: payment.lastName, middleName: payment.middleName, firstName: payment.firstName,
				creditCardNumber: payment.cardNumber, cardVerificationNumber: payment.verificationCode, 
				expirationMonth: payment.expirationDate && payment.expirationDate.length == 7 ? payment.expirationDate.substring(0,2) : "",
				expirationYear: payment.expirationDate && payment.expirationDate.length == 7 ? payment.expirationDate.substring(3) : ""
			}
		}

		axios.post('api/seatmaps', {...seatmapsInfo, pcc: '1ED5', pnr: props.pnr, confirmationCode: props.confirmationCode,
		payment: newPayment
	})
		.then(function (res) {
			console.log('seatmaps book', res, JSON.parse(res.data))
			let json = JSON.parse(res.data)
			for(let v of json){
				if(v.rsp){
					enqueueSnackbar(v.rsp, {variant: 'error'})
				}else{
					enqueueSnackbar('success', {variant: 'success'})
				}
			}
		})
		.catch(function (error) {
			console.error(error);
			alert(error)
		}).then(function () {
			setSubmiting(false)
		});
	}

	const changeSeatmaps=(value)=>{
		console.log('change seat maps', value)
		setSeatmapsInfo(value)
	}

	// const changeValue=(values)=>{
	// 	console.log('values', values)
	// 	setPayment(values)
	// }

	let childRef = createRef()


    return (
		<>
		<Button onClick={()=>setOpen(true)}>{intl.get('SUCCESS.SEATMAP_SERVICE')}</Button>
		<Dialog
	fullScreen
	open={open}
	onClose={()=>setOpen(false)}
	aria-labelledby="responsive-dialog-title"
	// TransitionComponent={Transition}
  >
	<DialogTitle>
	  {intl.get("SEATMAP.SEATMAP")}
	</DialogTitle>
	<DialogContent>
		<Seatmap submiting={submiting} changeSeatmaps={changeSeatmaps} 
		{...{cid:props.cid, pcc: "1ED5", pnr: props.pnr, travelers: props.travelers, passengers: props.passengers}}
		// changeValue={changeValue}
		onRef={childRef}
		/>
	</DialogContent>
		<DialogActions>
		<Button autoFocus onClick={()=>setOpen(false)}>
		  Close
		</Button>
		<Button onClick={handleSeatmaps} autoFocus color='warning'>
		  Submit
		</Button>
	  </DialogActions>
	  </Dialog>
	  </>
    );
}

export default function Success() {
    const location = useLocation();
	const nagetive = useNavigate()
    console.log("success", location);
    const {state} = location
    let routing = JSON.parse(state.bookInfo.routing);

    useEffect(()=>{
        loadCss('/Flight-Hotel/vendor/bootstrap/css/bootstrap.min.css')
        loadCss('/Flight-Hotel/vendor/font-awesome/css/all.min.css')
        loadCss('/Flight-Hotel/css/stylesheet.css')
        loadCss('/Flight-Hotel/css/styles.css')
        loadCss('/Flight-Hotel/css/FlightDetails.css')
        loadCss('/css/flag.css')

        document.scrollingElement.scrollTop = 0
		
    },[])



  return (
	// <SnackbarProvider maxSnack={3}>
	<>
	<ButtonAppBar/>
    <div className="theme-page-section">
	<div className="container-fluid invoice-container">
		<div className="row align-items-center _pr-15 _pl-15">
			<div className="col-md-12"><h3>
				{intl.get("SUCCESS." + parseStatus(state.orderStatus))}</h3></div>
			{/* <div className="col-md-12 _mb-desk-20">
				<span className="font-weight-900 text-3"><i className="fas fa-check-circle _mr-desk-5" style={{color: "#ea5903"}}></i>{intl.get("SUCCESS.BOOKED_PROCESSING")}</span>
			</div> */}
			<div className="col-md-12 order-status__body">
				<div className="order-status__text">{intl.get("SUCCESS.NAME") + " " + state.bookInfo.passengers[0].firstName + ","}<br/>
				{state.orderStatus == 1 ? <PaymentBox currency={state.currency} 
				price={(routing.adultBasePrice+routing.adultTax)*state.bookInfo.adults + (routing.childBasePrice+routing.childTax)*state.bookInfo.children} 
				paymentLink={state.bookedRsp.airTicketBookingResponse.paymentLink} /> :
				<OtherBox orderStatus={state.orderStatus} openid={state.openid} />
				}
				</div>
				<div className="order-status__action-controls">
					{/* <p className="text-left _mb-desk-10 _mt-desk-20"><a href="Booking-success.html"><span style={{fontSize: "21px"}}>« </span>{intl.get("SUCCESS.VIEW_ORDER")}</a></p> */}
				</div>
			</div>
		</div>
	</div>
	<div className="container-fluid invoice-container">
		<div className="row align-items-center _pr-15 _pl-15">
    <div className="col-md-12"><h6>{intl.get("SUCCESS.BOOKING_ID")}:</h6></div>
			{/* <div className="col-md-12 order-status__header text-sm-left"><small className="order-status__label">FLYG {intl.get("SUCCESS.BOOKING_ID")}:</small>
				<div className="order-status__info">
				<span className="order-status__state">{intl.get("SUCCESS.RECORD_LOCATOR")}: </span><b className="order-status__number">{state.bookedRsp.airTicketBookingResponse.recordLocator}</b>
				</div>
				<div className="order-status__info">
				<span className="order-status__state">{intl.get("SUCCESS.CONFIRMATION_CODE")}: </span><b className="order-status__number">{state.bookedRsp.airTicketBookingResponse.confirmationCode}</b>
				</div>
			</div> */}
      <div className="col-md-12 order-status__header"><small className="order-status__label">{intl.get("SUCCESS.RECORD_LOCATOR")}: </small>
				<div className="order-status__info">
					<b className="order-status__number">{state.bookedRsp.airTicketBookingResponse.recordLocator}</b></div>
			</div>
      <div className="col-md-12 order-status__header"><small className="order-status__label">{intl.get("SUCCESS.CONFIRMATION_CODE")}: </small>
				<div className="order-status__info">
					<b className="order-status__number">{state.bookedRsp.airTicketBookingResponse.confirmationCode}</b></div>
			</div>
      <div className="col-md-12 order-status__header"><small className="order-status__label">{intl.get("SUCCESS.BOOK_DATE")}: </small>
				<div className="order-status__info">
					<b className="order-status__number">{moment(state.orderTime ? state.orderTime : new Date()).format("YYYY/MM/DD")}</b></div>
			</div>
			{/* <div className="col-md-12 order-status__body">
				<div className="order-status__text">
					{intl.get("SUCCESS.DESCRIPTION")}
        </div>
				<div className="order-status__action-controls"></div>
			</div> */}
		  </div>
	</div>
	
	<div className="container-fluid invoice-container">
		<div className="row align-items-center">
			<h6 className="traveler">{intl.get("SUCCESS.TRAVELERS")}</h6>
            {state.bookInfo.passengers.map((v, i)=>{
                return <Traveler key={i} passenger={v}/>
            })}
			{/* <MySeatmap {...{cid: routing.cid, pnr: state.bookedRsp.airTicketBookingResponse.recordLocator, confirmationCode:state.bookedRsp.airTicketBookingResponse.confirmationCode, travelers: {adults: state.bookInfo.adults, children: state.bookInfo.children}, passengers: state.bookInfo.passengers}}/> */}
			<Link to="/seatmapdemo" state={{routing,cid: routing.cid, pnr: state.bookedRsp.airTicketBookingResponse.recordLocator, 
				confirmationCode:state.bookedRsp.airTicketBookingResponse.confirmationCode, 
				travelers: {adults: state.bookInfo.adults, children: state.bookInfo.children}, 
				passengers: state.bookInfo.passengers,
				contact: state.bookInfo.contact
				}}>{intl.get('SUCCESS.SEATMAP_SERVICE')}</Link>
	  </div>
</div>
	
		
      <div className={"container-fluid invoice-container" + " " + style.payment}>
        <div className="row row-col-static row-col-mob-gap" id="sticky-parent" data-gutter="60">
		   <div className="col-md-12 _pt-mob-25">
            <div className="theme-payment-page-sections">
              <div className={"theme-payment-page-sections-item" + " " + style.item}>
                <div className="theme-search-results-item theme-payment-page-item-thumb _p-desk-15 ">
					
				<div className="row _br-3 _mv-10 " data-gutter="20">
					<div className="col-md-12 ">
						<div className="theme-search-results-item-flight-details-schedule">
						  <ul className="theme-search-results-item-flight-details-schedule-list">
							<li><i className="fa fa-plane theme-search-results-item-flight-details-schedule-icon"></i>
                            <TripDetail routing={routing} hideBookButton />
						</li>
					</ul>
				</div>
			  </div>
		</div>
					
 </div>
  </div>
          </div>
          </div>

        </div>
      </div>
	


	<div className="container-fluid invoice-container">
		<div className="row align-items-center">
			<div className="col-md-12 ">
        <OrderSummary displayBookButton={false} routing={routing} travelers={{adults: state.bookInfo.adults, children: state.bookInfo.children }} currency={state.currency} displaySetMarkup={false}/>
			</div>
 </div>
  </div>
  <BasicSpeedDial info={state}/>
    </div>
	</>
	// </SnackbarProvider>
  )
}
