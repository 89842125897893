import React, { useState } from 'react'
import { useFormContext } from "react-hook-form";
import intl from 'react-intl-universal';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { useEffect } from 'react';
import { countries } from '../../data/countries'

export default function Contact(props){
	const { register, watch, setValue, getValues, formState: { errors } } = useFormContext();
    const [disabled, setDisabled] = useState(true)
    // const [displaySame, setdisplaySame] = useState(false)

    useEffect(()=>{
        setDisabled(props.disabled)
        if(props.contact){
            setValue("contact", props.contact)
        }
    },[])

	// console.log("get values", getValues());
	if(watch("sameName")){
		// setValue("traveler.adult[0].lastName", "demo")
		let values = getValues();
		// values.traveler.
		for(let travelerType in values.traveler){
			console.log("traveler", travelerType, values.traveler[travelerType]);
			setValue(`traveler.${travelerType}[0].lastName`, watch("contact.lastName"))
			setValue(`traveler.${travelerType}[0].middleName`, watch("contact.middleName"))
			setValue(`traveler.${travelerType}[0].firstName`, watch("contact.firstName"))
			break
		}
	}

	function handleSetDisabled(){
		// console.log("set disabled", disabled);
		setDisabled(false)
	}


return(
	<div className="theme-payment-page-sections-item _p-20">
	<h3 className="theme-payment-page-sections-item-title font-weight-600">
		<i className="fa fa-address-book _pr-10 _r"></i>{intl.get("BOOK.CONTACT_INFORMATION")}
        <span className="Required"><span className="required">*</span>{intl.get("BOOK.REQUIRED_DESCRIPTION")}</span>
	</h3>

	 	  <div className="col-md-12 _p-15 ba-1" style={{marginBottom: "20px"}}>
 			  <div className="block-fieldset__body block-fieldset__body--alert-wrap">
 				  <div className="alert-box-inline alert-box-inline--info">
 					  <i className="fa fa-info-circle alert-box-inline__icn"></i>
 					  <p className="alert-box-inline__text">{intl.get("BOOK.CONTACT_CREDIT_CARD_ADDRESS_REQUIREMENT")}</p>
 				  </div>
 			  </div>
 		 </div>
	<h6 className="text-2 font-weight-600 mb-3">{intl.get("BOOK.CONTACT_INFORMATION_REQUIREMENTS")}</h6>
	<div className="theme-payment-page-form">
	  <div className="row row-col-gap" data-gutter="20">
        {props.displaySame && <div className="_mb-desk-10 col-md-12">
				  <div className="checkbox">
				  <input type="checkbox" id="contactsubscribe-cs_subscribe" className="checkbox__input" {...register("sameName")}/>
					<label className="checkbox__control" htmlFor="contactsubscribe-cs_subscribe"></label>
				<label className="checkbox__label text-2 font-weight-600" htmlFor="contactsubscribe-cs_subscribe">{intl.get("BOOK.NAME_SAME_FIRST_TRAVELER")}</label>
                </div>
		   </div>}
		   
		  		  
		<div className="col-sm-6 ">
			<div className="form-group">
				<label className="control-label">{intl.get("COMMON.LAST_NAME")}<span className="required">*</span></label>
				<input type="text" className="form-control" placeholder="Last Name" {...register("contact.lastName", {required: true, maxLength: 25})} maxLength="25" disabled={disabled}/>
				<p className="help-block help-block-error"></p>
			</div> </div>
		  
		<div className="col-sm-6">
			<div className="form-group">
				<label className="control-label">{intl.get("COMMON.MIDDLE_NAME")}</label>
				<input type="text" className="form-control" placeholder="Middle Name" {...register("contact.middleName", {required: false, maxLength: 25})} maxLength="25" disabled={disabled}/>
				<p className="help-block help-block-error"></p>
			</div></div>
		  
		  <div className="col-sm-6">
			<div className="form-group">
				<label className="control-label">{intl.get("COMMON.FIRST_NAME")}<span className="required">*</span></label>
				<input type="text" className="form-control" placeholder="First Name" {...register("contact.firstName", {required: true, maxLength: 25})} maxLength="25" disabled={disabled}/>
				<p className="help-block help-block-error"></p>
			</div> </div>
		  
		<div className="col-sm-6">
			</div>
		  
		  
		  <div className="col-md-6">
			<label className="control-label">{intl.get("BOOK.CONTACT_PHONE")}<span className="required">*</span></label>
		  <div className="row row-col-gap" data-gutter="20">

		   <div className="col-md-6 ">
				<div className="theme-payment-page-form-item form-group">
					 <input className="form-control" type="text" placeholder="12354568972" {...register("contact.phoneNumber", {required: true, maxLength: 20, pattern: /^[\d\s+]+$/i})} maxLength="20" disabled={disabled}/>
				</div>
		   </div>
			   
			</div>
		  </div>
		  
		 <div className="col-md-6 ">
		  <div className="form-group">
			  <label className="control-label" htmlFor="checkout-ch_email">{intl.get("BOOK.CONTACT_EMAIL")}<span className="required">*</span></label>
			<input className="form-control" type="text" placeholder="your@email.com" {...register("contact.emailAddress", {required: true, maxLength:35})} maxLength="35" disabled={disabled}/>
		  </div>
		</div> 
		<div className="col-md-6 ">
		  <div className="form-group">
			<label className="control-label">{intl.get("BOOK.CONTACT_ZIP_CODE")}</label>
			<input className="form-control" type="text" placeholder="" {...register("contact.zipCode", {maxLength:10})} maxLength="10" disabled={disabled}/>
		  </div>
		</div>
					  
		<div className="col-md-6">
			<label className="control-label">{intl.get("BOOK.CONTACT_COUNTRY")}</label>
				<div className="theme-payment-page-form-item form-group">
				  <i className="fa fa-angle-down"></i>
				  <select className="form-control" {...register("contact.country")} disabled={disabled} value={props.contact && (props.contact.country == "United States of America" ? "United States": props.contact.country)}>
						<option>{intl.get("BOOK.CONTACT_COUNTRY")}</option>
						{countries.map((item)=>{return <option key={item.abbr + item.code} value={item.name}>{item.name}</option>})}
				</select>
				</div>
		  </div>
		<div className="col-md-6">
		  <div className="form-group">
			<label className="control-label" htmlFor="checkout-ch_email">{intl.get("BOOK.CONTACT_STATE")}</label>
			<input className="form-control" type="text" placeholder={intl.get("BOOK.CONTACT_STATE_PLACEHOLDER")} {...register("contact.state", {maxLength: 30})} maxLength="30" disabled={disabled}/>
		  </div>
		</div>
		<div className="col-md-6 ">
		  <div className="form-group">
			<label className="control-label" htmlFor="checkout-ch_email">{intl.get("BOOK.CONTACT_CITY")}</label>
			<input className="form-control" type="text" placeholder={intl.get("BOOK.CONTACT_CITY_PLACEHOLDER")} {...register("contact.city", {maxLength: 30})} maxLength="30" disabled={disabled}/>
		  </div>
		</div>	
		  
		  <div className="col-md-12">
		  <div className="theme-payment-page-form-item form-group">
			<label className="control-label" htmlFor="checkout-ch_email">{intl.get("BOOK.CONTACT_ADDRESS")}</label>
			<input className="form-control" type="text" placeholder="" {...register("contact.address1", {maxLength: 80})} maxLength="80" disabled={disabled}/>
		  </div>
		</div>	 
	   </div>
	</div>
    {
        props.displaySame || <h6 className="text-2 font-weight-600 mb-3">
		need to modify?
        
        <IconButton color="primary" aria-label="need to modify" onClick={handleSetDisabled} >
            <EditIcon/>
        </IconButton>
	</h6>
    }
    
  </div>
)
}
