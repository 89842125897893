import { Alert, Button, Stack, AppBar,LinearProgress } from '@mui/material';
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import OrderSummary from '../../components/OrderSummary';
import TripDetail from '../../components/TripDetail';
import loadCss from "../../common/loadCss";
import 'moment/locale/zh-cn';
import intl from 'react-intl-universal';
import ProgressBox from '../../components/ProgressBox';
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import Contact from '../../components/Contact';
import Traveler from '../../components/Traveler';
import PaymentDetail from '../../components/PaymentDetail';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ButtonAppBar from '../../components/ButtonAppBar';



export default function Payment() {
    const {key} = useParams();
    const navigate = useNavigate()
    // console.log("key", key);
    const [bookData, setBookData] = useState(null)
    const [loaded, setLoaded] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null)
    const methods = useForm();
    const [travelers, setTravelers] = useState(null)
    // const [responseData, setResponseData] = useState(null)
    const [progress, setProgress] = useState(false)


    useEffect(()=>{
        loadCss('/Flight-Hotel/vendor/bootstrap/css/bootstrap.min.css')
        loadCss('/Flight-Hotel/vendor/font-awesome/css/all.min.css')
        loadCss('/Flight-Hotel/css/stylesheet.css')
        loadCss('/Flight-Hotel/css/styles.css')
        loadCss('/Flight-Hotel/css/FlightDetails.css')
        loadCss('/css/flag.css')

       
        if(key){
            fetch('../api/share?key='+ key)
            .then(response => response.json())
            .then(data =>{
              console.log("data", data);
              const json = JSON.parse(data);
              // console.log("json", json);
              // console.log(json.bookReq.routing);
              // setResponseData(JSON.parse(json.bookRsp))

              let bookInfo = {req: {routing: JSON.parse(json.bookReq.routing), 
                travelers: {adults: json.bookReq.adults, children: json.bookReq.children}, 
                currency: json.bookReq.currency,
                contact: json.bookReq.contact,
                passengers: json.bookReq.passengers,
                payment: json.bookReq.payment,
                userInfo: json.bookReq.userInfo,
                dateTime: json.bookReq.dateTime,
                domain: json.bookReq.domain,
                cid: json.bookReq.cid,
                openId: json.bookReq.openId,
                shareInfo: json.bookReq.shareInfo
              },
            rsp: JSON.parse(json.bookRsp)}
            let travelerList = []
            let adultIndex = 0;
            let childrenIndex = 0;
            let infantIndex=0;
            let index = 0;
            for(let passenger of json.bookReq.passengers){
              console.log('passenger', passenger);
              if(passenger.type == "adult"){
                travelerList.push({key: "travelerAdult"+adultIndex, index: index + 1, type: passenger.type, number: adultIndex, value: passenger, disabled: true})
                adultIndex++
              }else if(passenger.type == "children"){
                travelerList.push({key: "travelerChild"+childrenIndex, index: index + 1, type: passenger.type, number: childrenIndex, value: passenger, disabled: true})
                childrenIndex++
              }else{
                travelerList.push({key: "travelerInfant"+infantIndex, index: index + 1, type: passenger.type, number: infantIndex, value: passenger, disabled: true})
                infantIndex++
              }

              index++;
            }

            // console.log(bookInfo);
            setBookData(bookInfo)
            setTravelers(travelerList)
            console.log("book info", bookInfo);
            setLoaded(true)
            })
            .catch(err=>{console.error(err); setErrorMsg(err.message);setLoaded(true)});
            
          }else{
            setErrorMsg("DATA NOT FOUND");
            setLoaded(true)
          }
    },[])

    const onSubmit = data => {
		  console.log("submit", data);
      setProgress(true)
      data.payment.expirationYear = moment(data.payment.expiration, 'MM-YY').format('YYYY')
      data.payment.expirationMonth = moment(data.payment.expiration, 'MM-YY').format('MM')
      bookData.req.payment = data.payment

      let params = {...bookData, paymentType: 0}
      console.log('params', params)
      axios.post('/api/payment', params)
      .then(function (res) {
        setProgress(false)
        console.log(res)
        let json = JSON.parse(res.data)
        if("APIError" in json){
          for(let error of json.APIError.errors){
            alert(error.message)
            break
          }
        }else{
          for(let passenger of bookData.req.passengers){
            for(let passengerInfo of json.tktStatusResponse.passengerInfo){
              if(passenger.firstName == passengerInfo.firstName && passenger.lastName == passengerInfo.lastName){
                passenger.tktnumber = passengerInfo.tktnumber
              }
            }
          }

          navigate(`/success`, {state: {bookInfo: {routing: JSON.stringify(bookData.req.routing), passengers: bookData.req.passengers, 
            adults: bookData.req.travelers.adults, children: bookData.req.travelers.children }, 
        bookedRsp: {airTicketBookingResponse: {recordLocator: bookData.rsp.airTicketBookingResponse.recordLocator, 
          confirmationCode: bookData.rsp.airTicketBookingResponse.confirmationCode,paymentLink: null}},
        currency: bookData.req.currency, orderTime: bookData.req.dateTime, orderStatus: 2
    }});
        }
      })
      .catch(function (error) {
        setProgress(false)
        console.error(error)
        alert(error)
      }).then(function () {

      });
  }

  return (
    (loaded) ? (errorMsg ? <Stack spacing={2}><Alert severity="error">{errorMsg}</Alert></Stack> : 
    <>
    {progress && <AppBar position="fixed"><LinearProgress /></AppBar>}
    <ButtonAppBar/>
    <div className="theme-page-section">
	<div className="container-fluid invoice-container">
		<div className="row align-items-center _pr-15 _pl-15">
			<div className="col-md-12 order-status__header text-sm-left"><small className="order-status__label">{intl.get("PAYMENT.BOOKING_ID")}:</small>
				<div className="order-status__info">
					<b className="order-status__number">{bookData.rsp.airTicketBookingResponse.recordLocator}</b></div>
			</div>
		  </div>
	</div>	
      <div className="container-fluid invoice-container">
        <div className="row row-col-static row-col-mob-gap" id="sticky-parent" data-gutter="60">
		   <div className="col-md-12 _pt-mob-25">
            <div className="theme-payment-page-sections">
              <div className="theme-payment-page-sections-item ">
                <div className="theme-search-results-item theme-payment-page-item-thumb _p-desk-15 ">
					
				<div className="row _br-3 _mv-10 " data-gutter="20">
					<div className="col-md-12 ">
						<div className="theme-search-results-item-flight-details-schedule">
						  <ul className="theme-search-results-item-flight-details-schedule-list">
							<li><i className="fa fa-plane theme-search-results-item-flight-details-schedule-icon"></i>
                            <TripDetail routing={bookData.req.routing} hideBookButton />
						</li>
					</ul>
				</div>
			  </div>
		</div>
					
 </div>
  </div>
          </div>
          </div>

        </div>
      </div>
	


	<div className="container-fluid invoice-container">
		<div className="row align-items-center">
			<div className="col-md-12 ">
        <OrderSummary displayBookButton={false} routing={bookData.req.routing} travelers={bookData.req.travelers} currency={bookData.req.currency}/>
			</div>
 </div>
  </div>
  <FormProvider {...methods}>
  <form onSubmit={methods.handleSubmit(onSubmit)}>
  <div className="container-fluid invoice-container">
		<div className="row align-items-center">
    <Contact displaySame={false} disabled={true} contact={bookData.req.contact} />
    </div>
  </div>
  <div className="container-fluid invoice-container">
		<div className="row align-items-center">
      {/* <Traveler key="travelerAdult0" index={1} type={"adult"} number={0} disabled={true}/> */}
    {travelers.map((item)=>{return <Traveler {...item} />})}
    </div>
  </div>
	<div className="container-fluid invoice-container">
		<div className="row align-items-center">
      <PaymentDetail displaySelect={true} payment={bookData.req.payment} displayPaymentButton={true} info={bookData}/>
    </div>
  </div>
  </form>
  </FormProvider>
</div></>)  : <ProgressBox/>
  )
}
