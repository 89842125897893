import React, { useState } from 'react'
import intl from 'react-intl-universal';

export default function OrderSummary(props) {
    const [markup, setMarkup] = useState(0)

    // function calculateTotalPrice(routing, travelers){
    //     return (routing.adultBasePrice + routing.adultTax) * travelers.adults + (routing.childBasePrice + routing.childTax) * travelers.children
    // }

    function handleChangeMarkup(e){
      if(e.target.value && e.target.value.indexOf(".") < 0){
        e.target.value = ~~e.target.value
      }

      if(e.target.value){
        setMarkup(parseFloat(e.target.value))
        props.changeMarkup(parseFloat(e.target.value))

      }else{
        setMarkup(0)
        props.changeMarkup(0)
      }

    }

  return (
    <div className="sticky-col">
				  <div className="theme-sidebar-section _mb-10">
				  <h5 className="theme-sidebar-section-title">{intl.get("BOOK.ORDER_SUMMARY")}</h5>
                <div className="theme-sidebar-section-charges">
                  <ul className="theme-sidebar-section-charges-list">
                    <li className="theme-sidebar-section-charges-item">
                      <h5 className="theme-sidebar-section-charges-item-title">{intl.get("BOOK.ORDER_AIR_TICKET")}</h5>
					  <p className="theme-sidebar-section-charges-item-subtitle">{intl.get("CABIN.E")}</p>
                      <p className="theme-sidebar-section-charges-item-subtitle"></p>
                      {/* <p className="theme-sidebar-section-charges-item-price _mt-10">{intl.get('FLIGHT.SELLING_PRICE', {price: (props.routing.adultBasePrice*1) + (props.routing.adultTax) *1 })}</p> */}
                      <p className="theme-sidebar-section-charges-item-price _mt-10">{intl.get('PRICE', {currency: intl.get("CURRENCY."+ props.currency + ".SYMBOL").defaultMessage(props.routing.currency), price: props.routing.adultBasePrice + props.routing.adultTax })}</p>
                      {props.GDSPrice && <p className="theme-sidebar-section-charges-item-subtitle">GDS: {intl.get('PRICE', {currency: intl.get("CURRENCY."+ props.currency + ".SYMBOL").defaultMessage(props.routing.currency), price: props.GDSPrice })}</p>}
                    </li>
                    {props.displaySetMarkup &&                     <li className="theme-sidebar-section-charges-item">
                    <h5 className="theme-sidebar-section-charges-item-title">{intl.get("FLIGHT.SET_MARKUP")}</h5>
                    <input type="number" value={markup} onChange={handleChangeMarkup} width="3rem" min="0" max="1000" step="0.01" ></input>
                    {/* <p className="theme-sidebar-section-charges-item-subtitle">{intl.get("FLIGHT.MARKUP_DESCRIPTION")}</p> */}
                    </li>}

					  <li className="theme-sidebar-section-charges-item">
						  <h5 className="theme-sidebar-section-charges-item-title">{intl.get("BOOK.TRAVELERS_NUMBER")}</h5>
						  <p className="theme-sidebar-section-charges-item-subtitle"></p>
						  <p className="theme-sidebar-section-charges-item-price _mt-10">{props.travelers.adults + props.travelers.children}</p>
                    </li>
					  
                  </ul>
                  <p className="theme-sidebar-section-charges-total">{intl.get("BOOK.ORDER_TOTAL")}<span>{intl.get('PRICE', {currency: intl.get("CURRENCY."+ props.currency + ".SYMBOL").defaultMessage(props.routing.currency), price: (props.routing.adultBasePrice + props.routing.adultTax) * props.travelers.adults + (props.routing.childBasePrice + props.routing.childTax)*props.travelers.children + markup*(props.travelers.adults + props.travelers.children)})}</span></p>
					{/* <p className="theme-sidebar-section-charges-item-subtitle">{intl.get("BOOK.ORDER_CURRENCY_DESCRIPTION", {currency: intl.get('CURRENCY.'+props.currency+'.NAME')})}</p> */}
          {/* <p className="theme-sidebar-section-charges-item-subtitle">{intl.get("FLIGHT.MARKUP_DESCRIPTION")}</p> */}
          {/* <p className="theme-sidebar-section-charges-item-subtitle">{intl.get("FLIGHT.INVOICE_CREDIT_TOTAL")}{intl.get('PRICE', {currency: intl.get("CURRENCY."+ props.currency + ".SYMBOL").defaultMessage(props.routing.currency), price: Math.round(((props.routing.adultBasePrice + props.routing.adultTax) * props.travelers.adults + (props.routing.childBasePrice + props.routing.childTax)*props.travelers.children + markup*(props.travelers.adults + props.travelers.children))*(1-3.0/100)*100)/100})}</p> */}
                </div>
              </div>
  {props.displayBookButton ? <div className="btn-wrapper">
   <button type="submit" className="btn checkout-form__btn mkt-chkt-pscrlnw-btn">
       <span className="btn-text mkt-chkt-pscrlnw-btn">{intl.get("BOOK.ORDER_BOOK")}</span>
       <i className="btn-icn hif hif-arrow-right"></i>
       {/* <span className="btn-preloader"></span> */}
       {/* <i className="fa fa-arrow-right"></i> */}
   </button>
   </div> : null}  
</div>	
  )
}
