import React, { Component, useEffect, useState } from 'react'
import axios from 'axios';
import { useLocation } from 'react-router-dom'
import loadCss from "../../common/loadCss";
import { Link, useNavigate } from 'react-router-dom';
import TripDetail from '../../components/TripDetail';
import intl from 'react-intl-universal';
import { Box, IconButton, LinearProgress, Typography, AppBar, Skeleton, Snackbar, Stack, Button, List, ListItem, Dialog, DialogTitle, DialogContent, DialogContentText, Divider, Alert, Toolbar } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import moment from 'moment';
import Search from '../Search';

import OrderSummary from '../../components/OrderSummary';
import Contact from '../../components/Contact';
import Traveler from '../../components/Traveler';
import PaymentDetail from '../../components/PaymentDetail';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BasicCard from '../../components/BasicCard';
import PersistentDrawerLeft from '../../components/PersistentDrawerLeft';
import { set } from 'date-fns';
import CloseIcon from '@mui/icons-material/Close';
import MyTabs from '../../components/MyTabs';
import ButtonAppBar from '../../components/ButtonAppBar';


function deepEqual(object1, object2) {
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);
	if (keys1.length !== keys2.length) {
	  return false;
	}
	for (const key of keys1) {
		// console.log('key', key, 'key1', keys1)

		if(keys1.indexOf("airlineCode")>=0 &&
		(key != "departureAirportCode" || key != "arrivalAirportCode" || key != "flightNumber" || key != "airlineCode")
		){
			continue
		}
		
	  const val1 = object1[key];
	  const val2 = object2[key];
	//   console.log('equal', val1, val2)
	  const areObjects = isObject(val1) && isObject(val2);
	  if (
		areObjects && !deepEqual(val1, val2) ||
		!areObjects && val1 !== val2
	  ) {
		return false;
	  }
	}
	return true;
  }
  function isObject(object) {
	return object != null && typeof object === 'object';
  }

function FlightDetail(props){
	console.log("book", props)
	const navigate = useNavigate();
	const methods = useForm();
	const [progress, setProgress] = useState(false);
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState("this is snackbar");
	const [markup, setMarkup] = useState(0)
	const [displaySearch, setDisplaySearch] = useState(false)
	const [modalOpen, setModalOpen] = useState(false)
	const [tripData, setTripData] = useState(props.routing)

	// const rules = [{name: 'Fare', messages: props.fareRules}]
	// if(props.routing.brand && props.routing.brands){
	// 	rules.push({name: 'Brand', messages: props.round.brand.brands.map((brand)=> `${attr.name}: ${attr.value}`)})
	// }

	// if(props.NDCRule){
	// 	for(let NDCRuleKey in props.NDCRule){
	// 		let NDCRule = {name: NDCRuleKey}
	// 		if(NDCRuleKey === 'penalty'){
	// 			for(let penalty of NDCRuleKey[NDCRuleKey]){
	// 				for()
	// 			}
	// 		}
	// 	}
	// }

	let GDSPrice = null

	if(props.fullData){
		for(let full of props.fullData){
			if(deepEqual(props.routing.trips, full.trips)){
				GDSPrice = full.adultBasePrice + full.adultTax
				break
			}
		}
	}

	useEffect(()=>{
		console.log('effect')
	},[tripData])

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	  };
	// const { register, handleSubmit, formState: { errors } } = useForm();

	// const { register, handleSubmit, watch, formState: { errors } } = useForm();
	// console.log("errors", formState);


	// console.log("flight detail user info", sessionStorage.getItem("userInfo"), !sessionStorage.getItem("userInfo"))

  	const onSubmit = data => {
		console.log(sessionStorage.getItem("userInfo"))
		  console.log("submit", data);
		  let passengers = [];
		  for(let travelerType in data.traveler){
			  for(let traveler of data.traveler[travelerType]){
				  passengers.push({firstName: traveler.firstName, middleName: traveler.middleName, lastName: traveler.lastName, type: travelerType, birthday: traveler.birthday,
					gender: traveler.sex,
					nationality: traveler.nationality || null,
					idIssueCountry: traveler.idIssueCountry || null,
					idType: traveler.idType || null,
					idNumber: traveler.idNumber || null,
					idExpirationDate: traveler.idExpirationDate || null
				})
			  }
		  }

		  console.log('passengers', passengers);

		let userInfo = null;
		let cid = props.routing.cid
		let accountCode = null;
		if(sessionStorage.getItem("userInfo")){
			userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
			accountCode = userInfo.AccountCode
			if(!cid && userInfo.type == "traveloutlet"){
				cid = "gtttvo"
			}
		}
		
		let routing = {...tripData}
		delete routing.sells
		if(props.travelers.adults > 0){
			routing.adultBasePrice = Math.round((parseFloat(routing.adultBasePrice) + markup)*100)/100
		}

		if(props.travelers.children > 0){
			routing.childBasePrice = Math.round((parseFloat(routing.childBasePrice) + markup)*100)/100
		}

		routing.adultSalesPrice = routing.adultBasePrice
		routing.childSalesPrice = routing.childBasePrice

		routing.totalPrice = Math.round(((routing.adultBasePrice + routing.adultTax) * props.travelers.adults + (routing.childBasePrice + routing.childTax)*props.travelers.children)*100)/100

		if(!cid && props.shareInfo && props.shareInfo.sourceType == "traveloutlet"){
			cid = "gtttvo"
		}

		  let params = {adults: props.travelers.adults, 
			children: props.travelers.children,
			routing: JSON.stringify(routing),
			passengers: passengers,
			contact: data.contact,
			userInfo: userInfo,
			currency: props.currency,
			dateTime: new Date(),
			domain: `${window.location.protocol}//${window.location.host}`,
			openId: sessionStorage.getItem("openid"),
			shareInfo: props.shareInfo ? props.shareInfo : null,
			cid: cid
		};

		setProgress(true);
		console.log('post', params)

		axios.post('api/book', params)
		.then(function (res) {
		let json = JSON.parse(res.data);
		// console.log("booked json", json)
		if("APIError" in json){
			for(let error of json.APIError.errors){
				setMessage(error.message)
				setOpen(true);
				break
			}
		}else if ("airTicketPriceChangedResponse" in json){
			setMessage("Ticket price changed, please re-search.")
			setOpen(true);
		}
		else{
			navigate('/success', {state: {bookedRsp: json, bookInfo: params, currency: props.currency, orderStatus: 1}});
		}			
		})
		.catch(function (error) {
		console.error(error, typeof(error));
		setMessage(error+"");
		setOpen(true);
		}).then(function () {
		setProgress(false);
		});
	  }
	

	let travelerDetails = [];
	let index = 0;
	for(let i = 0; i<props.travelers.adults; i++){
		travelerDetails.push(<Traveler key={"travelerAdult" + i} index={index+1} type={"adult"} number={i} disabled={false} />);
		index++;
	}

	for(let i = 0; i<props.travelers.children; i++){
		travelerDetails.push(<Traveler key={"travelerChild" + i} index={index+1} type={"child"} number={i} disabled={false}/>);
		index++;
	}

	for(let i = 0; i<props.travelers.infants; i++){
		travelerDetails.push(<Traveler key={"travelerInfant" + i} index={index+1} type={"infant"} number={i} disabled={false}/>);
		index++;
	}

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
		  return;
		}
	
		setOpen(false);
	  };

	function handleClick(){
		setOpen(true)
	}

	function changeMarkup(markup){
		// let routing = {...props.routing}
		// if(props.travelers.adults > 0){
		// 	routing.adultBasePrice = (routing.adultBasePrice + markup)*100/100
		// }

		// if(props.travelers.children > 0){
		// 	routing.childBasePrice = (routing.childBasePrice + markup)*100/100
		// }

		// routing.totalPrice = (routing.totalPrice + markup)*100/100
		setMarkup(markup)
	}

	function changeShowType(showType){
		console.log("show type", showType);
		setDisplaySearch(showType)
	}

	const chooseSell=(item)=>{
		console.log('choose sell', item)
		if(item){
			setTripData(item)
		}else{
			setTripData(props.routing)
		}
	}


    return(
		<>
		<ButtonAppBar/>
<div className="theme-page-section theme-page-section-lg _mt-desk-30">
	
	 <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        // message={message}
      >
		<Alert severity="error">{message}</Alert>
	  </Snackbar>
      <div className="container">
	  {progress ?<AppBar position="fixed"><LinearProgress /></AppBar>: null }
	  {
		props.shareSourceType == "traveloutlet" && <Box>
		<Typography variant="h6" gutterBottom component="div">
			{intl.get("BOOK.CHANGE_DATE")}
		<IconButton color="primary" aria-label="Edit" onClick={()=>{setDisplaySearch(!displaySearch)}} >
			<EditIcon/>
		</IconButton>
		  </Typography>
	</Box>
	  }

	  {displaySearch && <Search showClose={true} changeShowType={showType => changeShowType(showType)} disabled={false}/> }
	  <FormProvider {...methods}>
	  <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="row row-col-static row-col-mob-gap" id="sticky-parent" data-gutter="60">
          <div className="col-md-8 _pt-mob-25 _pb-mob-25">
            <div className="theme-payment-page-sections">
              <div className="theme-payment-page-sections-item">
                <div className="theme-search-results-item theme-payment-page-item-thumb _p-desk-15 ">
					<div className="row">
					<div className="col-md-8">
					</div>

				</div>
				<div className="row _br-3 _mv-10 " data-gutter="20">
					<div className="col-md-12 ">
						<div className="theme-search-results-item-flight-details-schedule">
						  <ul className="theme-search-results-item-flight-details-schedule-list">
							<li><i className="fa fa-plane theme-search-results-item-flight-details-schedule-icon"></i>
                            <TripDetail routing={tripData} hideBookButton={true} hideFareRules={true}/>
				</li>
				<li>
					{tripData.brand && tripData.brand.brands && tripData.brand.brands.length > 0 && tripData.brand.brands[0].name}
				<Button onClick={()=>{setModalOpen(true)}}>{intl.get('BOOK.SHOW_FARE_RULES')}</Button>
				{/* <Modal
        open={modalOpen}
        onClose={()=>{setModalOpen(false)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {intl.get('BOOK.FARE_RULES')}
          </Typography>

		  {props.fareRules.map((item, index)=> {return  <Typography key={index} sx={{ mt: 2 }}>
            {item}
			</Typography>})}

        </Box>
      </Modal> */}
	  <Dialog
	  	fullScreen
        open={modalOpen}
        onClose={()=>{setModalOpen(false)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle>
		{intl.get('BOOK.FARE_RULES')}
        </DialogTitle> */}
		<AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={()=>setModalOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
			{intl.get('BOOK.FARE_RULES')}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
			{/* <PersistentDrawerLeft handleClose={()=>setModalOpen(false)} fareRules={props.prebookingInfo} brandRules={props.routing.brand || null}/> */}
		{/* {tripData.brand && tripData.brand.brands && tripData.brand.brands.map((brand)=>{
				console.log('brand', brand)
				return brand.attrs.map((attr)=>{
					console.log('attr', attr)
					return <Typography gutterBottom>{`${attr.name}: ${attr.value}`}</Typography>
				})
				
			})}
		  <Divider />
		  {props.fareRules.map((item, index)=> {return  <Typography key={index} sx={{ mt: 2 }}>
            {item}
			</Typography>})} */}
		<MyTabs fareRules={props.prebookingInfo} brandRules={props.routing.brand || null}/>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
				</li>
				<li>
					{props.routing.sells && props.routing.sells.length > 0 && <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{intl.get("BOOK.CHANGE_CABIN")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
			<List>
				{props.routing.sells.map(sell=>{return <ListItem key={sell.key}>
				  <BasicCard routing={sell} chooseSell={chooseSell} fullData={props.fullData} travelers={props.travelers}/>
				</ListItem>})}
			</List>
        </AccordionDetails>
      </Accordion>}

				</li>
			</ul>
		</div>
	  </div>
</div>
 </div>
  </div>
			
  {!sessionStorage.getItem("userInfo") || (props.shareInfo && props.shareInfo.isShare) ?<Contact displaySame={true} disabled={false} /> :<></>}
  
{/* {!sessionStorage.getItem("userInfo") ?<Payment/>:<></>} */}
			  {
				  travelerDetails
			  }

				{/* {props.shareSourceType == "traveloutlet" && <PaymentDetail payment={null} displayPaymentButton={false}/>} */}
           </div>
          </div>
         <div className="col-md-4 ">
			<OrderSummary routing={tripData} travelers={props.travelers} displayBookButton={true} currency={props.currency} changeMarkup={changeMarkup} displaySetMarkup={props.displayMarkup} GDSPrice={GDSPrice} />
         </div>
        </div>
		</form>
		</FormProvider>
      </div>
    </div>
	</>
    )
}




export default function Book(props) {
    const location = useLocation();
	const [initDone, setInitDone] = useState(false)
	const [message, setMessage] = useState("this is snackbar");
    const navigate = useNavigate();
	const [verified, setVerified] = useState(false)
	const [displayMarkup, setDisplayMarkup] = useState(false)
	const [prebookingInfo, setPrebookingInfo] = useState(null)
	// const [fareRule, setFareRule] = useState(null)
	// const [NDCRule, setNDCRule] = useState(null)
	// const [isTraveloutlet, setIsTraveloutlet] = useState(false)
    // console.log(props)

    console.log("book location", location);

    useEffect(()=>{
        loadCss('/Flight-Hotel/vendor/bootstrap/css/bootstrap.min.css')
        loadCss('/Flight-Hotel/vendor/font-awesome/css/all.min.css')
        loadCss('/Flight-Hotel/css/stylesheet.css')
        loadCss('/Flight-Hotel/css/styles.css')
        loadCss('/Flight-Hotel/css/FlightDetails.css')
        loadCss('/css/book.css')
		console.log("book openid", sessionStorage.getItem("openid"));
		let userInfo = null;
		let cid = location.state.routing.cid
		// if(location.state.routing.cid)
		if(location.state.shareInfo && location.state.shareInfo.isShare){
			if(!cid && location.state.shareSourceType == "traveloutlet"){
				cid = "gtttvo"
			}

			if(location.state.searchInfo){
				if(location.state.searchInfo.from){
					localStorage.from = location.state.searchInfo.from
					if(location.state.searchInfo.to){
						localStorage.to = location.state.searchInfo.to
					}

					if(location.state.searchInfo.departDate){
						localStorage.departDate = location.state.searchInfo.departDate
					}

					if(location.state.searchInfo.returnDate){
						localStorage.returnDate = location.state.searchInfo.returnDate
					}
				}
			}
		}else{
			if(sessionStorage.getItem("userInfo") != null){
				userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
				console.log("user info", userInfo);
				if(userInfo.AccountCode && userInfo.AccountCode != "null"){
					setDisplayMarkup(true)
				}

				if(!cid && userInfo.type && userInfo.type == "traveloutlet"){
					cid = "gtttvo"
					// setIsTraveloutlet(true)
				}
			}
		}

		let params = {adults: location.state.travelers.adults, 
			children: location.state.travelers.children,
			routing: JSON.stringify(location.state.routing),
			cid,
			isPrebooking: true
		};

		console.log('params', params)

		// console.log(location.state.searchInfo.href)
		// if (process.env.NODE_ENV !== 'development'){
		// 	// if(cid != "flygndc"){		
		// 	// 	axios.post('api/book', params)
		// 	// 	.then(function (res) {
		// 	// 	  // console.log("Prebooking", res)
		// 	// 	  let json = JSON.parse(res.data);
		// 	// 	  console.log("booked json", json)
		// 	// 	  if("APIError" in json){
		// 	// 		  for(let error of json.APIError.errors){
		// 	// 			  // console.log(error.errorId, error.message);
		// 	// 			  // setMessage(error.message);
	  
		// 	// 			  alert(error.message)
		// 	// 		  }
	  
		// 	// 		  if(location.state.shareInfo && location.state.shareInfo.isShare){
		// 	// 			  sessionStorage.setItem('share', JSON.stringify(location.state.shareInfo))
		// 	// 			  navigate('/flight'+location.state.searchInfo.href)
		// 	// 		  }else{
		// 	// 			  navigate(-1);
		// 	// 		  }
					  
		// 	// 	  }else if("airTicketPriceChangedResponse" in json){
		// 	// 		  alert("Price changed, please re-search.");
		// 	// 		  if(location.state.shareInfo && location.state.shareInfo.isShare){
		// 	// 			  sessionStorage.setItem('share', JSON.stringify(location.state.shareInfo))
		// 	// 			  navigate('/flight'+location.state.searchInfo.href)
		// 	// 		  }else{
		// 	// 			  navigate(-1);
		// 	// 		  }
		// 	// 	  }else if("airTicketRuleResponse" in json){
		// 	// 		  let fareRuleList = []
		// 	// 		  for(let fareRule in json.airTicketRuleResponse.rules){
		// 	// 			  fareRuleList.push(json.airTicketRuleResponse.rules[fareRule])
		// 	// 		  }
		// 	// 		  setFareRules(fareRuleList)
		// 	// 	  }
		// 	// 	})
		// 	// 	.catch(function (error) {
		// 	// 	  console.error(error, typeof(error));
		// 	// 	  alert(error);
		// 	// 	  if(location.state.shareInfo && location.state.shareInfo.isShare){
		// 	// 		  sessionStorage.setItem('share', JSON.stringify(location.state.shareInfo))
		// 	// 		  navigate('/flight'+location.state.searchInfo.href)
		// 	// 	  }else{
		// 	// 		  navigate(-1);
		// 	// 	  }
		// 	// 	}).then(function () {
		// 	// 	  setVerified(true)
		// 	// 	});
		// 	//   }else{
		// 	// 	  setFareRules([])
		// 	// 	  setVerified(true)
		// 	//   }
		// }else{
		// 	if(location.state.routing.rules){
		// 		let routingRules=[]
		// 		for(let ruleKey in location.state.routing.rules){
		// 			routingRules.push(`${ruleKey}: ${location.state.routing.rules[ruleKey]}`)
		// 		}

		// 		setFareRules(routingRules)
		// 	}

		// 	setVerified(true)
		// }

		axios.post('api/book', params)
		.then(function (res) {
		  // console.log("Prebooking", res)
		  let json = JSON.parse(res.data);
		  console.log("booked json", json)
		  if("APIError" in json){
			  for(let error of json.APIError.errors){
				  // console.log(error.errorId, error.message);
				  // setMessage(error.message);

				  alert(error.message)
			  }

			  if(location.state.shareInfo && location.state.shareInfo.isShare){
				  sessionStorage.setItem('share', JSON.stringify(location.state.shareInfo))
				  navigate('/flight'+location.state.searchInfo.href)
			  }else{
				  navigate(-1);
			  }
			  
		  }else if("airTicketPriceChangedResponse" in json){
			  alert("Price changed, please re-search.");
			  if(location.state.shareInfo && location.state.shareInfo.isShare){
				  sessionStorage.setItem('share', JSON.stringify(location.state.shareInfo))
				  navigate('/flight'+location.state.searchInfo.href)
			  }else{
				  navigate(-1);
			  }
		  }else if("airTicketRuleResponse" in json){
			setPrebookingInfo(json.airTicketRuleResponse)
			//   let fareRuleList = []
			//   for(let fareRule in json.airTicketRuleResponse.rules){
			// 	  fareRuleList.push(`${fareRule}: ${json.airTicketRuleResponse.rules[fareRule]}`)
			//   }
			//   setFareRules(fareRuleList)

			//   if(json.airTicketRuleResponse.fullNdcRulls){
			// 	setNDCRule(json.airTicketRuleResponse.fullNdcRulls)
			//   }
		  }
		})
		.catch(function (error) {
		  console.error(error, typeof(error));
		  alert(error);
		  if(location.state.shareInfo && location.state.shareInfo.isShare){
			  sessionStorage.setItem('share', JSON.stringify(location.state.shareInfo))
			  navigate('/flight'+location.state.searchInfo.href)
		  }else{
			  navigate(-1);
		  }
		}).then(function () {
		  setVerified(true)
		});
    },[])


  return (
	        verified ? <FlightDetail {...location.state} displayMarkup={false} prebookingInfo={prebookingInfo}/> : 	
			<><AppBar position="fixed"><LinearProgress /></AppBar>
			<Box sx={{ width: '100%' }}>
		<Stack spacing={1}>
		<Skeleton animation="wave" variant="rectangular" width="100%" height={200} />
		<Skeleton animation="wave" variant="rounded" width="100%" height={250} />
		<Skeleton animation="wave" variant="rounded" width="100%" height={250} />
		</Stack>
		</Box>
		</>
    )
}
