import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const rules = []
  if(props.fareRules){
    // if(props.fareRules.rules && !props.fareRules.fullNdcRulls){
    //     let rule = {name: 'Fare', messages: []}
    //     for(let r in props.fareRules.rules){
    //         rule.messages.push(`${r}: ${props.fareRules.rules[r]}`)
    //     }

    //     rules.push(rule)
    // }

    if(props.fareRules.fullNdcRulls){
        for(let key in props.fareRules.fullNdcRulls){
            let rule = {name: key, messages: []}
            if(key == 'penalty' && props.fareRules.fullNdcRulls[key]){
                for(let penalty of props.fareRules.fullNdcRulls[key]){
                    for(let r of penalty.rules){
                        rule.messages.push(`${r.Name}: ${r.Value}`)
                    }
                }
            }

            if(key == 'baggage' && props.fareRules.fullNdcRulls[key]){
                for(let baggageKey in props.fareRules.fullNdcRulls[key]){
                    if(props.fareRules.fullNdcRulls[key][baggageKey]){
                        for(let seg of props.fareRules.fullNdcRulls[key][baggageKey]){
                            let measurementMsgs = []
                            if(seg.measurement){
                                for(let measurement of seg.measurement){
                                    measurementMsgs.push(`${measurement.MeasurementType}: ${measurement.Value}${measurement.Unit}`)
                                }
                            }

                            if(seg.quantity){
                              rule.messages.push(`${baggageKey}: ${seg.type}, ${seg.quantity} quantity${measurementMsgs.length > 0 ?  ", " + measurementMsgs.join("; "): ""}`)
                            }

                        }
                        
                    }
                }
            }

            rules.push(rule)
        }
    }
  }

  if(props.brandRules && props.brandRules.brands){
    let rule = {name: 'Attributes', messages: []}
    for(let brand of props.brandRules.brands){
        if(brand.attrs){
            for(let attr of brand.attrs){
                rule.messages.push(`${attr.name}: ${attr.value}`)
            }
        }
    }

    rules.push(rule)
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

//   console.log('rules', rules)

  const [ruleItem, setRuleItem] = useState(rules.length > 0 ? rules[0]: null)

  const handleClickItem=(item)=>{
    console.log('click item', item)
    setRuleItem(item)
    // setRules([name])
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Rules
          </Typography>
          <Button color="inherit" onClick={props.handleClose}>Close</Button>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {rules.map((rule) => (
            <ListItem key={rule.name} disablePadding>
              <ListItemButton onClick={()=>handleClickItem(rule)}>
                {/* <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon> */}
                <ListItemText primary={rule.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {ruleItem && ruleItem.messages.map((msg, i)=>(
        <Typography key={i} paragraph>
            {msg}
      </Typography>
        ))}

      </Main>
    </Box>
  );
}