import { Button } from '@mui/material'
import React, {useEffect} from 'react'
import { useFormContext } from "react-hook-form";
import intl from 'react-intl-universal';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import axios from 'axios';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  cursor: 'pointer'
}));

export default function PaymentDetail(props) {
  const { register, setValue, getValues, watch, formState: { errors } } = useFormContext();
  const [pay, setPay] = useState(false)
  // console.log("get values", getValues());
  useEffect(()=>{
    if(props.payment){
      setValue("payment", props.payment)
      setValue("payment.expiration", `${props.payment.expirationMonth}/${props.payment.expirationYear.substring(2)}`)
    }

    if(!props.displaySelect){
      setPay(true)
    }
  },[])

  const handleWechat =()=>{
    console.log('wechat');
    let params = {paymentType: 1,
      req: {openId: props.info.req.openId, cid: props.info.req.cid, routing: {totalPrice: props.info.req.routing.totalPrice}, currency: props.info.req.currency},
      rsp: {airTicketBookingResponse: {confirmationCode: props.info.rsp.airTicketBookingResponse.confirmationCode, 
        recordLocator: props.info.rsp.airTicketBookingResponse.recordLocator}},
      returnUrl: `${window.location.protocol}//${window.location.host}/paymentresult`}
    
    axios.post('/api/payment', params)
    .then(function (res) {
      console.log("user data", res)
      let jsonData = JSON.parse(res.data)
      window.location.href=`https://gttb2b.feiquanqiu.com/WebSit/WXPayment.aspx?sign=${jsonData.sign}`
    })
    .catch(function (error) {
      console.error("flight", error);
    }).then(function () {
      
    });
  }

  return (
    <div className="theme-payment-page-sections-item _p-20">
    <h3 className="theme-payment-page-sections-item-title">
        <i className="fas fa-credit-card" style={{marginRight: "10px"}}></i>{intl.get("PAYMENT.TITLE")}</h3>
        {props.displaySelect &&
        <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Item onClick={()=>{setPay(!pay)}}><img src="/Flight-Hotel/panelAirR/images/footer-payment-social.svg" alt="Image Alternative text" title="Image Title"/></Item>
        </Grid>
        <Grid item xs={12} md={8}>
          <Item onClick={()=>{handleWechat()}}>
          <img src="https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/checkout-bank-WeChat?wid=280&amp;hei=52&amp;fmt=jpeg&amp;qlt=90&amp;.v=0"/>
          </Item>
        </Grid>
      </Grid>
    </Box>}
      {pay && <div className="row row-col-gap" style={{paddingBottom: "30px"}} data-gutter="20">
           {/* <div className="col-md-12" style={{marginBottom: "20px"}}>
            <ul className="theme-payment-page-card-list">
              <li>
                <img src="/Flight-Hotel/panelAirR/images/footer-payment-social.svg" alt="Image Alternative text" title="Image Title"/>
              </li>
             </ul>
          </div> */}
<div className="col-md-6 " style={{paddingBottom: "0px"}}>
                    <label className="control-label">{intl.get("PAYMENT.CARDHOLDER_LAST_NAME")}</label>
                    <input type="text" className="form-control" placeholder="" {...register("payment.lastName", {required: true})}/>
                    <p className="help-block help-block-error"></p>
         </div>
<div className="col-md-6 " style={{paddingBottom: "0px"}}>
                    <label className="control-label">{intl.get("PAYMENT.CARDHOLDER_MIDDLE_NAME")}</label>
                    <input type="text" className="form-control" placeholder="" {...register("payment.middleName", {required: false})}/>
                    <p className="help-block help-block-error"></p>
         </div>
<div className="col-md-6 " style={{paddingBottom: "0px"}}>
                    <label className="control-label">{intl.get("PAYMENT.CARDHOLDER_FIRST_NAME")}</label>
                    <input type="text" className="form-control" placeholder="" {...register("payment.firstName", {required: true})}/>
                    <p className="help-block help-block-error"></p>
         </div>
        <div className="col-md-6" style={{paddingBottom: "0px"}}>
                <label className="control-label">{intl.get("PAYMENT.CARD_NUMBER")}</label>
                <input type="text" className="form-control" placeholder="xxxx xxxx xxxx xxxx" {...register("payment.creditCardNumber", {required: true})}/>
                <p className="help-block help-block-error"></p>
          </div>
          


            <div className="col-md-3">
              <div className="form-group">
               <label className="control-label">{intl.get("PAYMENT.EXPIRY_DATE")}</label>
                <input id="expiryDate" type="text" className="form-control" data-bv-field="expiryDate" required="" placeholder="MM/YY" {...register("payment.expiration", {required: true})}/>
              </div>
            </div>
            <div className="col-md-3">
               <div className="form-group">
                <label className="control-label">{intl.get("PAYMENT.VERIFICATION_CODE")}</label>
                <input id="cvvNumber" type="password" className="form-control" data-bv-field="cvvnumber" required="" placeholder="xxx" {...register("payment.cardVerificationNumber", {required: true})}/>
              </div>
            </div>
<div className="col-md-12">
  {props.displayPaymentButton && <Button type="submit" variant="contained">{intl.get("PAYMENT.CONFIRM_PAYMENT")}</Button>}

</div>
  </div>}
</div>
  )
}
