import React from "react";
import axios from 'axios';
import moment from 'moment';
import Enumerable from 'linq';
import intl from 'react-intl-universal';
import OwlCarousel from "react-owl-carousel";
import Loading from '../../components/Loading'
import loadCss from "../../common/loadCss";
import loadScript from '../../common/loadScript'
import TripDetail from "../../components/TripDetail";
import LinearProgress from '@mui/material/LinearProgress';
import {AppBar, Box, Grid, IconButton, Skeleton, Typography, Tabs, Tab, TextField, Slider, Autocomplete} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Search from "../Search";
import { useState, useEffect } from "react";
import PubSub from 'pubsub-js'
import Checkbox from '@mui/material/Checkbox';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
// import wx from 'weixin-js-sdk'

// import 'moment/locale/zh-cn';

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import MyBottomNavigation from "../../components/MyBottomNavigation";
import ButtonAppBar from "../../components/ButtonAppBar";




// if (process.env.NODE_ENV === 'development'){
// 	require('../../mock/index')
// }


function getUrlParam(name) {
	let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
	let r = window.location.search.substr(1).match(reg);  //匹配目标参数
	if (r != null) return unescape(r[2]); return null; //返回参数值
}


function FlightType(props){
	return props.isOneway ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
	<path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
  </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-right" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"/>
</svg>;
}

function formatDate(date){
	return `${date.substring(0,4)}/${date.substring(4,6)}/${date.substring(6)}`
}

function ShopResultsTitle(props) {
	const [filterAltOff, setFilterAltOff] = useState(false)
	// console.log("show results ticket", props);

	function handleClick(showType){
		props.changeShowType(showType)
	}

	const handleChange=(e)=>{
		props.filterNDC(e.target.checked)
	}

	const handleFilter=()=>{
		setFilterAltOff(!filterAltOff)
		props.handleFilter(!filterAltOff)
	}

	return (
		<Box>
			<Typography variant="h6" gutterBottom component="div">
			{props.origin != null ? intl.get("AIRPORT." + props.origin.toUpperCase()+".CITYNAME") : ""}  
			&nbsp;<FlightType isOneway={props.arrivalDate == null}/> &nbsp;
			{props.destination != null ? intl.get("AIRPORT." + props.destination.toUpperCase()+".CITYNAME") : ""}
			<IconButton color="primary" aria-label="Edit" onClick={()=>handleClick(true)}>
				<EditIcon/>
			</IconButton>
      		</Typography>
			<Typography variant="body1" gutterBottom>
				{props.departureDate != null ? moment(props.departureDate).format("MMM Do YYYY") : ""}
				{props.arrivalDate == null ? "" : " - " + moment(props.arrivalDate).format("MMM Do YYYY")}, 
				{(props.adults + props.children) + " " + intl.get("FLIGHT.TRAVELERS")},
				{props.cabin == "" ? intl.get("CABIN.E") : intl.get("CABIN." + props.cabin.toUpperCase())}
				{/* , Ndc only */}
					{/* <Checkbox onChange={handleChange}/> */}
					<IconButton color="primary" aria-label="filter" onClick={handleFilter}>
					{!filterAltOff ? <FilterAltIcon/> : <FilterAltOffIcon/>}
					</IconButton>
			</Typography>
		</Box>
	)
	// return <h2 className="text-8 mb-3 mb-sm-4 mt-4">{intl.get('FLIGHT.SHOPRESULTS')} <span className="text-4">{props.origin != null ? intl.get("AIRPORT." + props.origin.toUpperCase()+".CITYNAME") : ""}  <FlightType isOneway={props.arrivalDate == null}/> {props.destination != null ? intl.get("AIRPORT." + props.destination.toUpperCase()+".CITYNAME") : ""}  {props.departureDate != null ? props.departureDate : ""}{props.arrivalDate == null ? "" : " - " + props.arrivalDate}</span></h2>;
}

// function Item(props) {
// 	let stop = 0;
// 	for(let value of props.values){
// 		for(let trip of value.trips){
// 			if(trip.segments.length - 1 > stop){
// 				stop = trip.segments.length - 1;
// 			}
// 		}
// 	}

// 	return (<div className="item" onClick={() => props.handleClick({values: props.values, id: props.sellingPrice})}>
// 		<div className="airsubnav">
// 			<button className="airsubnavbtn" alt="airlines 1">
// 				<div className="airsubnavbtn-price"><span className="markup">{intl.get('PRICE', {currency: intl.get("CURRENCY."+ props.currency + ".SYMBOL").defaultMessage(props.currency), price: props.sellingPrice})}</span>
// 					<div className="fromDepart">
// 						<span>{props.departTime}</span>
// 						<span>{intl.get("AIRPORT." + props.depart + ".AIRPORTNAME").defaultMessage(props.depart)}</span>
// 						</div>
// 					{/* <div className="fromDepart">{intl.get('FLIGHT.FROM')} {intl.get("AIRPORT." + props.depart).defaultMessage(props.depart)} {intl.get("FLIGHT.AT")} {props.departTime}</div> */}
// 				</div>
// 				<div className="Direct">
// 					<div>{intl.get('FLIGHT.STOP', {stop})}</div>
// 					<div><i className="fa fa-suitcase suitcase"></i></div>
// 					<div style={{ lineHeight: '15px' }}>({props.option} {intl.get('FLIGHT.OPTION')})</div>
// 				</div>
// 			</button>
// 		</div>
// 	</div>
// 	);
// }

function Item(props) {
	let stop = 0;
	let brandName = ""
	let hasNDC = false

	for(let value of props.values){
		for(let trip of value.trips){
			if(trip.segments.length - 1 > stop){
				stop = trip.segments.length - 1;
			}
		}

		if(value.brand && value.brand.brands){
			for(let brand of value.brand.brands){
				brandName = brand.name
			}
		}

		if(value.cid === "flygndc"){
			hasNDC = true
		}
	}


	const handleClick=(data)=>{
		// console.log('pub', data);
		PubSub.publish('owlItem' + props.airlineCode, data)
	}

	return (<div className="item" onClick={() => handleClick({values: props.values, id: props.sellingPrice, fullData: props.fullData})}>
		<div className="airsubnav">
			<button className="airsubnavbtn" style={{paddingTop: '2px'}} alt="airlines 1">
				<div className="airsubnavbtn-price">
				<Grid container spacing={0}>
					<Grid item xs={6} style={{textAlign: "left", whiteSpace: "pre", fontSize: "small", fontWeight: "500"}}>
						<span>{brandName || " "}</span>
					</Grid>
					<Grid item xs={6} style={{textAlign: "right", fontSize: "small", fontWeight: "500"}}>
						<span>{hasNDC && "NDC"}</span>
					</Grid>
				</Grid>
					<span className="markup">{intl.get('PRICE', {currency: intl.get("CURRENCY."+ props.currency + ".SYMBOL").defaultMessage(props.currency), price: props.sellingPrice})}</span>
					<div className="fromDepart">
						<span>{props.departTime}</span>
						<span>{intl.get("AIRPORT." + props.depart + ".AIRPORTNAME").defaultMessage(props.depart)}</span>
						</div>
					{/* <div className="fromDepart">{intl.get('FLIGHT.FROM')} {intl.get("AIRPORT." + props.depart).defaultMessage(props.depart)} {intl.get("FLIGHT.AT")} {props.departTime}</div> */}
				</div>
				<div className="Direct">
					<div>{intl.get('FLIGHT.STOP', {stop})}</div>
					<div><i className="fa fa-suitcase suitcase"></i></div>
					<div style={{ lineHeight: '15px' }}>({props.option} {intl.get('FLIGHT.OPTION')})</div>
				</div>
			</button>
		</div>
	</div>
	);
}

// function ShowItem(props){
// 	const[flights, setFlights] = useState([])
// 	const[isShow, setIsShow] = useState(false)
// 	const[history, setHistory] = useState([])
// 	console.log('init show item')
// 	useEffect(()=>{
// 	console.log('show item');

// 		let sub = PubSub.subscribe('owlItem' + props.airlineCode, function(msg, data){
// 			console.log('owl item sub', msg, data);
// 			console.log('start history', history);
// 			setFlights(data.values)
			
// 			if(history == data.id){
// 				setIsShow(!isShow)
// 			}else{
// 				setIsShow(true)
// 			}

// 			setHistory(history.concat(data.id))
// 			console.log('end history', history);
// 		})

// 		return()=>{
// 			PubSub.unsubscribe(sub)
// 		}
// 	},[])

// 	return(
// 		isShow && (<div className="card-body cbcolor">
// 			<h6>{history.length}</h6>
// 		{flights.map((item) => { return <TripDetail key={item.key} hideBookButton={false} travelers={props.travelers} routing={item} currency={props.currency} /> })}
// 	</div>)
// 	)
// }

class ShowItem extends React.Component{
	constructor(props) {
		super(props)
		// console.log("card", props);

		this.state = { isShow: false, flights:[], history: null, baggage: {}, fullData: null}	
	}

	componentDidMount(){
		let that = this
		this.sub = PubSub.subscribe('owlItem' + this.props.airlineCode, function(msg, data){
			// console.log('owl item sub', msg, data);
			// console.log('start history', that.state.history);
			that.setState({flights: data.values, fullData: data.fullData})
		
			if(that.state.history == data.id){
				that.setState({isShow: !that.state.isShow})
			}else{
				that.setState({isShow: true})
			}
			that.setState({history: data.id})
			// console.log('end history', that.state.history);
		})

		this.mySub = PubSub.subscribe('baggage', function(msg, data){
            // console.log('sub', msg, data)
			that.setState({baggage: data})
        })
	}

	componentWillUnmount(){
		PubSub.unsubscribe(this.sub)
		PubSub.unsubscribe(this.mySub)
	}

	render(){
	return(
		this.state.isShow && (<div className="card-body cbcolor">
		{this.state.flights.map((item) => { return <TripDetail key={item.key} hideBookButton={false} travelers={this.props.travelers} routing={item} currency={this.props.currency} fullData={item.cid === "flygndc" ? this.state.fullData : null} /> })}
		    <div className="modal amenity-modal fade" tabIndex="-1" role="dialog" id="tooltip-modal" style={{ display: "none", zIndex: 1050 }} data-max-modal="" aria-hidden="true">
						<div className="modal-dialog" role="document">
							<div className="modal-content">
								<div className="modal-header">
									{/* <div className="modal-header__title">New York City-Taipei</div> */}
									<button className="modal-header__close" data-dismiss="modal"><i className="fa fa-times"></i></button>
								</div>

								<div className="modal-body">
									<div className="amenities-tooltip__item amenity-row amenity-row--new ">
										<div className="amenity-row__header"><div className="amenity-row__icn icn">
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M9 11V4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V11H16C17.1046 11 18 11.8954 18 13V19C18 20.1046 17.1046 21 16 21C16 21.5523 15.5523 22 15 22C14.4477 22 14 21.5523 14 21H10C10 21.5523 9.55229 22 9 22C8.44771 22 8 21.5523 8 21C6.89543 21 6 20.1046 6 19V13C6 11.8954 6.89543 11 8 11H9ZM14 4H10V11H14V4Z" fill="black"></path></svg>
										</div>
											<div className="amenity-row__text">	<div className="amenity-row__description">{intl.get("FLIGHT.CARRY_ON")} </div></div>
											<div className="amenity-row__allowance"><span className="amenity-row__value">{intl.get("FLIGHT.BAGGAGE", {baggage: this.state.baggage.carryOn})}</span></div></div>


										<div className="amenity-row__footer"></div></div>
									<div className="amenities-tooltip__item amenity-row--new amenity-row amenity-row--new ">
										<div className="amenity-row__header">
											<div className="amenity-row__icn icn">
												<svg width="24" height="21" viewBox="0 0 14 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4 1V4H2.33333C1.04467 4 0 5.03319 0 6.30769V17.6923C0 18.9668 1.04467 20 2.33333 20H3C3 20.5523 3.44771 21 4 21C4.55229 21 5 20.5523 5 20H9C9 20.5523 9.44772 21 10 21C10.5523 21 11 20.5523 11 20H11.6667C12.9553 20 14 18.9668 14 17.6923V6.30769C14 5.03319 12.9553 4 11.6667 4H10V1C10 0.447715 9.55228 0 9 0H5C4.44772 0 4 0.447715 4 1ZM9 2H5V4H9V2Z" fill="black"></path></svg>
											</div>
											<div className="amenity-row__text">	<div className="amenity-row__description">{intl.get("FLIGHT.CHECKED_BAG")} </div></div>
											<div className="amenity-row__allowance"><span className="amenity-row__value">{intl.get("FLIGHT.BAGGAGE", {baggage: this.state.baggage.checkedBaggage})}{this.state.baggage.baggageUnit}</span></div>
										</div>

										{/* <div className="amenity-row__footer">
											<div className="amenity-row__notes-row"><div className="amenity-row__note">•Up to 50 pounds/23 kilograms</div></div>
											<div className="amenity-row__notes-row"><div className="amenity-row__note">•Up to 62 linear inches/158 linear centimeters</div></div>
										</div> */}
									</div>
								</div>
							</div>
						</div>
			</div>
		</div>
	 	)
	)
	}
}

class Card extends React.Component {
	constructor(props) {
		super(props)
	}

	
	// componentDidMount(){
	// 	let that = this
	// 	this.mySub = PubSub.subscribe('baggage', function(msg, data){
    //         // console.log('sub', msg, data)
	// 		that.setState({baggage: data})
    //     })

	// 	// this.owlItemSub = PubSub.subscribe('owlItem', function(msg, data){
	// 	// 	console.log('owl item sub', msg, data);
	// 	// })
	// }

	// componentWillUnmount(){
	// 	PubSub.unsubscribe(this.mySub)
	// 	// PubSub.unsubscribe(this.owlItemSub)
	// }

	options = {
		margin: 15,
		// responsiveClass: true,
		responsiveRefreshRate: 300,
		// responsiveBaseElement: '.container>.row>div',
		responsiveBaseElement: '.col-lg-9.col-sm-auto',
		// nav: true,
		dots: true,
		autoplay: false,
		// navText: ["Prev", "Next"],
		smartSpeed: 500,
		responsive: {
			0: {
				items: 1,
			},
			350: {
				items: 2,
			},
			500: {
				items: 3,
			},
			// 576: {
			// 	items: 2,
			// },
			// 770: {
			// 	items: 3,
			// },
			800: {
				items: 4,
			}
		},
	}


	handleClick(item) {
		// // console.log("click item", item);
		// let currentShow = this.state.isShow;
		// if(this.state.id == item.id){
		// 	currentShow = !this.state.isShow;
		// }else{
		// 	currentShow = true;
		// }

		// this.setState({ isShow: currentShow, flights: item.values, id: item.id })
		// // this.props.changeShow(currentShow);
	}

	render() {
		let priceGroups = Enumerable.from(this.props.items).groupBy("i=>i.adultBasePrice + i.adultTax").select("{key:$.key(),value:$.toArray()}").toArray();
		// let itemList = []
		// for (let priceGroup of priceGroups) {
		// 	// itemList.push(<Item key={priceGroup.key} values={priceGroup.value} handleClick={item => this.handleClick(item)} sellingPrice={priceGroup.key} depart={priceGroup.value[0].trips[0].segments[0].departureAirportCode} departTime={priceGroup.value[0].trips[0].segments[0].departureTime.substring(11)} option={priceGroup.value.length} currency={this.props.currency} />)
		// 	itemList.push(<Item key={priceGroup.key} values={priceGroup.value} handleClick={item => this.handleClick(item)} sellingPrice={priceGroup.key} depart={priceGroup.value[0].trips[0].segments[0].departureAirportCode} departTime={priceGroup.value[0].trips[0].segments[0].departureTime.substring(11)} option={priceGroup.value.length} currency={this.props.currency} />)
		// }

		// priceGroups.map((priceGroup)=>{return <Item key={priceGroup.key} values={priceGroup.value} handleClick={item => this.handleClick(item)} sellingPrice={priceGroup.key} depart={priceGroup.value[0].trips[0].segments[0].departureAirportCode} departTime={priceGroup.value[0].trips[0].segments[0].departureTime.substring(11)} option={priceGroup.value.length} currency={this.props.currency} />})

		return (
			<div className="card">
				<div className="card-header">
					<div className="row align-items-center">
						<div className="col-lg-3 airlogo">
							<img className="icon-air-hotel" alt={this.props.airlineCode} src={"https://goprivate.wspan.com/sharedservices/images/airlineimages/logoAir" + this.props.airlineCode + ".gif"} />
							<span className="ResultAir_Carrier" >{intl.get('AIRLINE.' + this.props.airlineCode).defaultMessage(this.props.airlineCode)}</span>
						</div>
						<div className="col-lg-9 col-sm-auto text-center text-sm-left" style={{minWidth: '320px'}}>
							<OwlCarousel className="owl-theme" {...this.options} >
							{/* {itemList} */}
							{priceGroups.map((priceGroup)=>{return <Item key={priceGroup.key} values={priceGroup.value}
							airlineCode = {this.props.airlineCode}
							handleClick={item => this.handleClick(item)} sellingPrice={priceGroup.key} 
							depart={priceGroup.value[0].trips[0].segments[0].departureAirportCode} 
							departTime={priceGroup.value[0].trips[0].segments[0].departureTime.substring(11)} 
							option={priceGroup.value.length} currency={this.props.currency} fullData={this.props.items.filter(item=>item.cid !== "flygndc")} />})}
							</OwlCarousel>
						</div>
					</div>
				</div>
				<ShowItem airlineCode = {this.props.airlineCode} travelers={this.props.travelers} currency={this.props.currency}/>

			</div>
		)
	}
}


function CardTest(props){
	// console.log('card test', props);
	const[isShow, setIsShow] = useState(false)
	const[flights, setFlights] = useState([])
	const[id, setId] = useState(null)
	const[baggage, setBaggage] = useState({})
	const [age, setAge] = useState(0)
	const options = {
		margin: 15,
		// responsiveClass: true,
		nav: true,
		dots: true,
		autoplay: false,
		// navText: ["Prev", "Next"],
		smartSpeed: 1000,
		responsive: {
			0: {
				items: 2,
			},
			200: {
				items: 1,
			},
			410: {
				items: 2,
			},
			576: {
				items: 2,
			},
			768: {
				items: 3,
			},
			992: {
				items: 3,
			},
			1000: {
				items: 4,
			}
		},
	}

	useEffect(()=>{
        const mySub = PubSub.subscribe('baggage', function(msg, data){
            console.log('sub', msg, data)
			setBaggage(data)
        })

        return()=>{
            PubSub.unsubscribe(mySub)
        }
    },[])

	const handleClick = (item)=>{
		console.log('clicking item', item);
		console.log('is show', isShow, !isShow);
		console.log('age', age)
		// let currentShow = isShow
		// if(id == item.id){
		// 	currentShow = !isShow
		// }else{
		// 	currentShow = true
		// }

		// setIsShow(currentShow)
		// setFlights(item.values)
		// setIsShow(!isShow)
		// setId(item.id)
		// props.changeShow(currentShow)
		setAge(Math.random())
		setIsShow(!isShow)
	}

	return (
		<div className="card">
			<h6>{age}</h6>
			<div className="card-header">
				<div className="row align-items-center">
					<div className="col-lg-3 airlogo">
						<img className="icon-air-hotel" alt={props.airlineCode} src={"https://goprivate.wspan.com/sharedservices/images/airlineimages/logoAir" + props.airlineCode + ".gif"} />
						<span className="ResultAir_Carrier" >{intl.get('AIRLINE.' + props.airlineCode).defaultMessage(props.airlineCode)}</span>
					</div>
					<div className="col-lg-9 col-sm-auto text-center text-sm-left">
						<OwlCarousel className="owl-theme" {...options} >
						{Enumerable.from(props.items).groupBy("i=>i.adultBasePrice + i.adultTax").select("{key:$.key(),value:$.toArray()}").toArray()
						.map((item)=>{
							return <Item key={item.key} values={item.value} handleClick={handleClick} sellingPrice={item.key} depart={item.value[0].trips[0].segments[0].departureAirportCode} departTime={item.value[0].trips[0].segments[0].departureTime.substring(11)} option={item.value.length} currency={props.currency} />
						})}
						</OwlCarousel>
					</div>
				</div>
			</div>
			<ShowItem/>			

		</div>
	)
}


function CardSkeleton(){
	return(
		<div className="card">
			<Grid container spacing={8}>
				<Grid item xs>
					{/* <Skeleton animation="wave" /> */}
					{/* <Skeleton variant="circular" width={40} height={40} /> */}
					<Skeleton variant="rectangular" height={180} />
				</Grid>
			</Grid>
		</div>
	)
}

 
function isInclude(name){
	var js= /js$/i.test(name);
	var es=document.getElementsByTagName(js?'script':'link');
	for(var i=0;i<es.length;i++) 
	if(es[i][js?'src':'href'].indexOf(name)!=-1)return true;
	return false;
}

function compare(p){ //这是比较函数
    return function(m,n){
        var a = m[p];
        var b = n[p];
        return a - b; //升序
    }
}

function checkFound(routing, data){
	let found = false
	for(let d of data)
	{
		if(d.mainAirlineCode == routing.mainAirlineCode && d.adultBasePrice == routing.adultBasePrice &&
			d.adultTax == routing.adultTax && d.childBasePrice == routing.childBasePrice && d.childTax == routing.childTax
			&& d.trips.length == routing.trips.length){
				for(let i = 0;i<d.trips.length;i++){
					if(d.trips[i].segments.length == routing.trips[i].segments.length){
						for(let j=0;j<d.trips[i].segments.length;j++){
							if(d.trips[i].segments[j].airlineCode == routing.trips[i].segments[j].airlineCode
								&& d.trips[i].segments[j].departureAirportCode == routing.trips[i].segments[j].departureAirportCode
								&& d.trips[i].segments[j].departureTime == routing.trips[i].segments[j].departureTime
								&& d.trips[i].segments[j].arrivalAirportCode == routing.trips[i].segments[j].arrivalAirportCode
								&& d.trips[i].segments[j].arrivalTime == routing.trips[i].segments[j].arrivalTime){
									found = true
								}
						}
					}
				}
		}


	}

	return found
}

function valuetext(value) {
	return `${value}°C`;
  }

  function valueLabelFormat(value) {
	    
	return `${value}:00`;
  }

export default class Flight extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searchParams: null,
			data: [],
			cards: null,
			isLoading: true,
			selectedValue: "",
			loaded:[],
			travelers: {},
			// isShow: false,
			progress: {value: 0, buffer: 10},
			showType: false,
			tabValue: null,
			tabItems: [],
			currency: null,
			errors: [],
			prevData:[],
			airlines:[],
			filterAirlines: [],
			filterStops:[],
			showFilter: false,
			rangeTime: [0, 24],
			filterFlightNumbers: []
		}
	}

	componentDidMount() {
		loadCss("css/loaders.css/loaders.min.css")
		loadCss("css/loaders.css/theme.css")
		loadCss('Flight-Hotel/vendor/font-awesome/css/all.min.css')
		// loadCss('css/bootstrap.min.css')
		// console.log("css", isInclude('stylesheet.css'));
		loadCss('css/stylesheet.css')
		loadCss('css/FlightDetails.css')
		loadCss('css/flight.css')

		// loadScript(process.env.PUBLIC_URL + '/js/jquery.min.js')
        // loadScript(process.env.PUBLIC_URL + '/js/jquery-ui.min.js')
        loadScript(process.env.PUBLIC_URL + '/js/bootstrap.bundle.min.js')

		let cid = null
		alert('openid: '+ sessionStorage.getItem("openid"))
		if(sessionStorage.getItem("userInfo")){
			const userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
			if(userInfo.type == "traveloutlet"){
				cid = "gtttvo"
			}
		}

		if(getUrlParam('cid')){
			cid = getUrlParam('cid')
		}

		var flights = getUrlParam('flights');
		let searchParams = null;
		if (flights != null) {
			var flightsItem = flights.split('/');
			if (flightsItem.length < 3) {
				alert("FLIGHT INVALID");
			}

			var from = flightsItem[0];
			var to = flightsItem[1];

			document.title = intl.get("FLIGHT.TITLE", {from: intl.get("AIRPORT." + from.toUpperCase()+".CITYNAME").defaultMessage(from), to: intl.get("AIRPORT." + to.toUpperCase()+".CITYNAME").defaultMessage(to)})

			var departDate = flightsItem[2];
			
			var returnDate = null;
			if (flightsItem.length == 4) {
				returnDate = flightsItem[3];
			}

			let adults = 0, children = 0, infants = 0;
			let adultsParam = getUrlParam('adults');
			let childrenParam = getUrlParam('children');
			let infantsParam = getUrlParam('infants');
			if(!!adultsParam){
				adults = parseInt(adultsParam)
			}

			if(!!childrenParam){
				children = parseInt(childrenParam)
			}

			if(!!infantsParam){
				infants = parseInt(infantsParam)
			}
			
			// console.log(intl.getInitOptions())

			let cabin = getUrlParam('cabin')
			let currency = "";
			if(localStorage.getItem("currency")){
				currency = localStorage.getItem("currency")
			}else if(intl.getInitOptions().currentLocale == "zh-cn"){
				currency = "CNY"
			}else if (intl.getInitOptions().currentLocale == "en-us"){
				currency = "USD"
			}

			this.setState({currency: currency})


			this.setState({travelers: {adults, children, infants}})
			let accountCode = null
			if(sessionStorage.getItem("userInfo") != null){
				let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
				accountCode = userInfo.AccountCode
			}

			searchParams = { origin: from, destination: to, departureDate: departDate, arrivalDate: returnDate, adults, children, cabin, currency, accountCode, cid }
			// console.log("search params", searchParams)
			this.setState({ searchParams: searchParams })

			// console.info(`from: ${from}, to: ${to}, depart date: ${departDate}, return date: ${returnDate}`);
			let that = this;

			// const timer = setInterval(() => {
			// 	if (this.state.progress.value > 100) {
			// 		this.setState({progress:{value: 98,
			// 			buffer: 99
			// 			}});
			// 	  } else {
			// 		const diff = Math.random() * 10;
			// 		const diff2 = Math.random() * 10;
			// 		this.setState({progress:{value: this.state.progress.value + diff,
			// 		buffer: this.state.progress.value + diff + diff2
			// 		}});
			// 	  }
			// }, 500);

			axios.post('api/flights', searchParams)
				.then(function (response) {
					// console.log("flights", response);

					var data = JSON.parse(response.data);
					console.log("flight res", data, that.state.data);

					if("APIError" in data){
						for(let error of data.APIError.errors){
							that.setState({errors: that.state.errors.concat(error.message)})
						}
					}else if(!!data.airTicketListResponse.currency && data.airTicketListResponse.currency != currency){
						that.setState({currency: data.airTicketListResponse.currency})
					}

					var routings = data.airTicketListResponse.routings;
					let newRoutings = []
					for(let routing of routings){
						routing.cid = data.airTicketListResponse.customerSessionId
						routing.sells=[]
						if("upsell" in routing && routing.upsell == false && "brand" in routing && routing.brand && routing.brand.group){
							for(let secondaryRouting of routings){
								if("upsell" in secondaryRouting && secondaryRouting.upsell == true && "brand" in secondaryRouting && secondaryRouting.brand && routing.brand.group == secondaryRouting.brand.group){
									routing.sells.push(secondaryRouting)
								}
							}
						}

						if("upsell" in routing && routing.upsell == true){
							continue
						}

						// console.log(`cid: ${routing.cid}, air: ${routing.mainAirlineCode}, total: ${routing.adultBasePrice  + routing.adultTax}`, routing)
						// console.log(routing)
						// let totalPrice = (routing.adultBasePrice + routing.adultTax) * adults + (routing.childBasePrice + routing.childTax) * children;
						// routing.totalPrice = Math.round(totalPrice * 100)/100
						let found = checkFound(routing, that.state.data)
						// console.log('is found', routing.cid, routing, found)
						if(!found){
							newRoutings.push(routing)
						}
					}

					// console.log('last new routings', data.airTicketListResponse.customerSessionId, newRoutings);
					that.setState(prevState=>{
						return{data: prevState.data.concat(newRoutings), airlines: Enumerable.from(prevState.data.concat(newRoutings)).select("x=>x.mainAirlineCode").distinct().toArray()}
					}, ()=>{
						console.log('last routings state', data.airTicketListResponse.customerSessionId, that.state.data);
					})					
				})
				.catch(function (error) {
					console.error("flight", error);
				}).then(function () {
					// console.log('flight then')
					// console.log('flight loaded', that.state.loaded)
					// that.setState({ isLoading: false, loaded: that.state.loaded.concat("flight")}, ()=>{
					// 	console.log('flight then func', that.state.loaded);
					// });
					
					// clearInterval(timer);
					that.setState({isLoading: false})
					that.setState(prevState=>{
						return{loaded: prevState.loaded.concat('flight')}
					},()=>{
						console.log('flight then func', that.state.loaded);
					})

				});

				// axios.post('api/flights', { ...searchParams, cid: 'flygndc' })
				// .then(function (response) {
				// 	var data = JSON.parse(response.data);
				// 	console.log("flygndc res", data);

				// 	var routings = data.airTicketListResponse.routings;
				// 	let newRoutings = []
				// 	for(let routing of routings){
				// 		// console.log(`cid: ${routing.cid}, air: ${routing.mainAirlineCode}, total: ${routing.adultBasePrice  + routing.adultTax}`, routing)
				// 		routing.cid = data.airTicketListResponse.customerSessionId
				// 		routing.sells=[]
				// 		if("upsell" in routing && routing.upsell == false && "brand" in routing && routing.brand && routing.brand.group){
				// 			for(let secondaryRouting of routings){
				// 				if("upsell" in secondaryRouting && secondaryRouting.upsell == true && "brand" in secondaryRouting && secondaryRouting.brand && routing.brand.group == secondaryRouting.brand.group){
				// 					routing.sells.push(secondaryRouting)
				// 				}
				// 			}
				// 		}

				// 		if("upsell" in routing && routing.upsell == true){
				// 			continue
				// 		}

				// 		let found = checkFound(routing, that.state.data)
				// 		// console.log('is found', routing.cid, routing, found)
				// 		if(!found){
				// 			newRoutings.push(routing)
				// 		}
				// 	}

				// 	console.log('last new routings', 'flygndc', newRoutings)
				// 	that.setState(prevState=>{
				// 		return {data: prevState.data.concat(newRoutings), airlines: Enumerable.from(prevState.data.concat(newRoutings)).select("x=>x.mainAirlineCode").distinct().toArray()}
				// 	}, ()=>{
				// 		console.log('last routings state', data.airTicketListResponse.customerSessionId, that.state.data);
				// 	})
				// })
				// .catch(function (error) {
				// 	console.error("flygndc", error);
				// }).then(function () {
					
				// 	that.setState({isLoading: false})
				// 	that.setState(prevState=>{
				// 		return{loaded: prevState.loaded.concat('flygndc')}
				// 	},()=>{
				// 		console.log('flygndc loaded', that.state.loaded)
				// 	})
					
				// });

			// 	if (process.env.NODE_ENV != 'development'){
			// 		// axios.post('api/flights', { ...searchParams, sourceFrom: "apply" })
			// 		// .then(function (response) {
			// 		// 	// console.log("apply flights", response);
			// 		// 	var data = JSON.parse(response.data);				
			// 		// 	var routings = data.airTicketListResponse.routings;
			// 		// 	that.setState({ data: that.state.data.concat(routings) })
			// 		// })
			// 		// .catch(function (error) {
			// 		// 	console.error("apply", error);
			// 		// }).then(function () {
			// 		// 	that.setState({ isLoading: false, loaded: that.state.loaded.concat("apply") })
			// 		// });

			// 		this.setState({ isLoading: false, loaded: that.state.loaded.concat("apply") })
			// }else{
			// 	this.setState({ isLoading: false, loaded: that.state.loaded.concat("apply") })
			// }

		// 	if (process.env.NODE_ENV != 'development'){
		// 	axios.post('api/flights', {...searchParams, departureFlex: 3})
		// 	.then(function (response) {
		// 		// console.log("flights flex", response);
		// 		var data = JSON.parse(response.data);
		// 		let dateToPrices = []
		// 		for(let routing of data.airTicketListResponse.routings){
		// 			for(let trip of routing.trips){
		// 				for(let segment of trip.segments){
		// 					dateToPrices.push({date: moment(segment.departureTime).format("YYYY-MM-DD"), price: routing.totalPrice })
		// 					break
		// 				}
		// 				break
		// 			}
		// 		}

		// 		dateToPrices.sort((a, b) => (moment(a.date) > moment(b.date)) ? 1 : -1);
		// 		// console.log(dateToPrices);
		// 		that.setState({tabItems: dateToPrices, tabValue: moment(departDate).format("YYYY-MM-DD")})
		// 	})
		// 	.catch(function (error) {
		// 		console.error("flex", error);
		// 	}).then(function () {

		// 	});
		// }
		}
		else {
			document.title = intl.get("FLIGHT.TITLE", {from: "", to: ""})
			this.setState({ isLoading: false})
		}
	}

	changeLang=e=>{
        let lang = e.target.value;
		this.setState({selectedValue: lang})
        // console.log("lang", e.target.value);
    }

	// changeShow=(isShow)=>{
	// 	// console.log("show", isShow);
	// 	this.setState({isShow})
	// }

	changeShowType=(showType)=>{
		this.setState({showType})
	}


	handleChange = (event, newValue) => {
		let a  = moment(this.state.searchParams.departureDate);
		let b = moment(newValue)
		let diff = a.diff(b, 'days')
		// console.log(`/flight?flights=${this.state.searchParams.origin}/${this.state.searchParams.destination}/${moment(newValue).format("YYYYMMDD")}${this.state.searchParams.arrivalDate != null ? "/" + this.state.searchParams.arrivalDate : ""}&cabin=${this.state.searchParams.cabin}&adults=${this.state.searchParams.adults}&children=${this.state.searchParams.children}&infants=0`);

		window.location = `/flight?flights=${this.state.searchParams.origin}/${this.state.searchParams.destination}/${moment(newValue).format("YYYYMMDD")}${this.state.searchParams.arrivalDate != null ? "/" + moment(this.state.searchParams.arrivalDate).add(-diff, 'days').format("YYYYMMDD") : ""}&cabin=${this.state.searchParams.cabin}&adults=${this.state.searchParams.adults}&children=${this.state.searchParams.children}&infants=0`;
	//   this.setState({tabValue: newValue})
	};

	filterNDC =(value)=>{
		if(value){
			this.setState({prevData: this.state.data})
			this.setState({data: this.state.data.filter(item=>item.cid === "flygndc")})
		}else{
			this.setState({data: this.state.prevData})
		}
		// console.log('filter', this.state.data.filter(item=>item.cid === "flygndc"))
		// this.setState({data: this.state.data.filter(item=>item.cid === "flygndc")})
	}

	handleFilterByStops=(count)=>{
		if(this.state.prevData.length == 0){
			// this.setState({prevData: this.state.data})
			this.setState(prevState=>{
				return {prevData: prevState.data}
			},()=>{
				// console.log('prev data', this.state.prevData);
			})
		}

		if(this.state.filterStops.indexOf(count) >= 0){
			let filterStops = this.state.filterStops.filter(x=>x!=count)
			this.setState({filterStops: filterStops})
			if(filterStops.length > 0){
				let newData = []
				for(let data of this.state.prevData.length == 0 ? this.state.data : this.state.prevData){
					let stops = 0;
					for(let trip of data.trips){
						if(trip.segments.length-1>stops){
							stops = trip.segments.length-1
						}
					}
	
					if(filterStops.indexOf(stops) >=0 || (stops>=2 && filterStops.indexOf(2)>=0)){
						newData.push(data)
					}
				}
	
				this.setState({data: newData})
			}else{
				this.setState({data: this.state.prevData.length == 0 ? this.state.data : this.state.prevData})
			}

		}else{
			let filterStops = this.state.filterStops.concat(count)
			this.setState({filterStops: filterStops})
			if(filterStops.length>0){
				let newData = []
				for(let data of this.state.prevData.length == 0 ? this.state.data : this.state.prevData){
					let stops = 0;
					for(let trip of data.trips){
						if(trip.segments.length-1>stops){
							stops = trip.segments.length-1
						}
					}

					if(filterStops.indexOf(stops) >=0 || (stops>=2 && filterStops.indexOf(2)>=0)){
						newData.push(data)
					}

				}
	
				this.setState({data: newData})
			}else{
				this.setState({data: this.state.prevData.length == 0 ? this.state.data : this.state.prevData})
			}

		}
		
	}

	handleFilterByAirline=(airline)=>{
		if(this.state.prevData.length == 0){
			// this.setState({prevData: this.state.data})
			this.setState(prevState=>{
				return {prevData: prevState.data}
			},()=>{
				// console.log('prev data', this.state.prevData);
			})
		}

		if(this.state.filterAirlines.indexOf(airline) >= 0){
			this.setState({filterAirlines: this.state.filterAirlines.filter(x=>x!=airline)})
			let xs = []
			for(let air of this.state.filterAirlines.filter(x=>x!=airline)){
				xs.push(`x.mainAirlineCode=='${air}'`)
			}

			if(xs.length > 0){
				this.setState({data: Enumerable.from(this.state.prevData.length == 0 ? this.state.data : this.state.prevData).where("x=>" + xs.join(' || ')).toArray()})
			}else{
				this.setState({data: this.state.prevData.length == 0 ? this.state.data : this.state.prevData})
			}

			// this.setState(prevState=>{
			// 	return{filterAirlines: prevState.filterAirlines.filter(x=>x!=airline)}
			// },()=>{
			// 	let xs = []
			// 	for(let air of this.state.filterAirlines){
			// 		xs.push(`x.mainAirlineCode=${air}`)
			// 	}
			// 	console.log('xs', xs, this.state.filterAirlines);
			// 	if(xs.length>0){
			// 		console.log('airline', Enumerable.from(this.state.data).where("x=>" + xs.join(' || ')).toArray())
			// 		this.setState({data: Enumerable.from(this.state.prevData).where("x=>" + xs.join(' || ')).toArray()})
			// 	}else{
			// 		console.log(this.state.prevData)
			// 		this.setState({data: this.state.prevData})
			// 	}
			// })
		}else{
			this.setState({filterAirlines: this.state.filterAirlines.concat(airline)})
			let xs = []
			for(let air of this.state.filterAirlines.concat(airline)){
				xs.push(`x.mainAirlineCode=='${air}'`)
			}

			console.log('<0', xs)
			if(xs.length > 0){
				this.setState({data: Enumerable.from(this.state.prevData.length == 0 ? this.state.data : this.state.prevData).where("x=>" + xs.join(' || ')).toArray()})
			}else{
				console.log('length<=0', this.state.prevData, this.state.data)
				this.setState({data: this.state.prevData.length == 0 ? this.state.data : this.state.prevData})
			}

			// this.setState(prevState=>{
			// 	return{filterAirlines: prevState.filterAirlines.concat(airline)}
			// },()=>{
			// 	let xs = []
			// 	for(let air of this.state.filterAirlines){
			// 		xs.push(`x.mainAirlineCode='${air}'`)
			// 	}
			// 	console.log('xs', xs, this.state.filterAirlines);
			// 	if(xs.length>0){
			// 		console.log('airline', Enumerable.from(this.state.data).where("x=>" + xs.join(' || ')).toArray())
			// 		this.setState({data: Enumerable.from(this.state.prevData).where("x=>" + xs.join(' || ')).toArray()})
			// 	}else{
			// 		this.setState({data: this.state.prevData})
			// 	}
			// })
		}
	}

	handleFilter=(off)=>{
		this.setState({showFilter: off})
	}

	handleChangeTime = (event, newValue) => {
		this.setState({rangeTime: newValue})
	  };

	handleChangeCommitted=(event, value)=>{
		let newData = []

		if(this.state.prevData == null || this.state.prevData.length == 0){
			this.setState({prevData: this.state.data})

			for(let data of	this.state.data){
				// console.log('data', data);
				let departureTime = new Date(data.trips[0].segments[0].departureTime);
				// let time = new Date(data.trips[0].segments[0].departureTime);
				let start = new Date(departureTime.getFullYear(), departureTime.getMonth(), departureTime.getDate(), value[0])
				let end = new Date(departureTime.getFullYear(), departureTime.getMonth(), departureTime.getDate(), value[1])
				console.log(start, departureTime, end, departureTime>=start && departureTime<=end)
				if(departureTime>=start && departureTime<=end){
					
					newData.push(data)
				}
			}
		}else{

			for(let data of	this.state.prevData){
				// console.log('data', data);
				let departureTime = new Date(data.trips[0].segments[0].departureTime);
				// let time = new Date(data.trips[0].segments[0].departureTime);
				let start = new Date(departureTime.getFullYear(), departureTime.getMonth(), departureTime.getDate(), value[0])
				let end = new Date(departureTime.getFullYear(), departureTime.getMonth(), departureTime.getDate(), value[1])
				console.log(start, departureTime, end, departureTime>=start && departureTime<=end)
				if(departureTime>=start && departureTime<=end){
					
					newData.push(data)
				}
			}
		}
		// if(value[0] != 0 || value[1] != 24){
		// 	this.setState({prevData: this.state.data})

		// }
		// console.log('committed', value, this.state.data);
		

		this.setState({data: newData})
	  }
	
	handleCollapse = ()=>{
		if(this.state.filterFlightNumbers.length == 0){
			let flightNumbers = []
			let data = this.state.prevData.length == 0 ? this.state.data : this.state.prevData
			for(let routing of data){
				for(let trip of routing.trips){
					for(let segment of trip.segments){
						if(flightNumbers.indexOf(segment.airlineCode + segment.flightNumber) < 0){
							flightNumbers.push(segment.airlineCode + segment.flightNumber)
						}
					}
				}
			}

			this.setState({filterFlightNumbers: flightNumbers.sort()})
		}
	}

	handleFilterFlightNumber = (flightNumber)=>{
		if(flightNumber){
		let newData = []

		if(this.state.prevData == null || this.state.prevData.length == 0){
			this.setState({prevData: this.state.data})

			for(let data of	this.state.data){
				let hasFlightNumber = false
				for(let trip of data.trips){
					for(let segment of trip.segments){
						if(segment.airlineCode + segment.flightNumber == flightNumber){
							hasFlightNumber = true
							break
						}
					}
				}
				
				if(hasFlightNumber){
					newData.push(data)
				}
			}
		}else{

			for(let data of	this.state.prevData){
				let hasFlightNumber = false
				for(let trip of data.trips){
					for(let segment of trip.segments){
						if(segment.airlineCode + segment.flightNumber == flightNumber){
							hasFlightNumber = true
							break
						}
					}
				}
				
				if(hasFlightNumber){
					newData.push(data)
				}
			}
		}	

		this.setState({data: newData})
	  }else{
		this.setState({data: this.state.prevData})
	  }
	}

	render() {
		// let groups = Enumerable.from(this.state.data).orderBy("i=>i.adultBasePrice + i.adultTax").groupBy("i=>i.mainAirlineCode").select("{key:$.key(),value:$.toArray()}").toArray();
		return this.state.isLoading ?  (<Loading/>) :  (
			<>
			<ButtonAppBar/>
			<section className="container">
				{this.state.loaded.length != 1 && <AppBar position="fixed"><LinearProgress /></AppBar>}
				<div className="row">
					<div className="col mb-2">
						{ this.state.showType == false ? 
						<ShopResultsTitle {...this.state.searchParams} changeShowType={(showType)=>this.changeShowType(showType)} filterNDC={this.filterNDC} handleFilter={this.handleFilter} /> : 
						<Search showClose={true} changeShowType={(showType)=>this.changeShowType(showType)} />  
						}
					</div>
				</div>
				<div className="row">
					{this.state.showFilter && 				<aside className="col-lg-3">
        <div className="bg-light shadow-md rounded p-3">
          <h3 className="text-5">{intl.get('FLIGHT.FILTER')}</h3>
          <hr className="mx-n3" />
          <div className="accordion accordion-alternate style-2 mt-n3" id="toggleAlternate">
            <div className="card">
              <div className="card-header" id="stops">
                <h5 className="mb-0"> <a href="#" data-toggle="collapse" data-target="#togglestops" aria-expanded="false" aria-controls="togglestops" className="collapsed">{intl.get('FLIGHT.FILTER_STOPS')}</a> </h5>
              </div>
              <div id="togglestops" className="collapse" aria-labelledby="stops">
                <div className="card-body">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" id="nonstop" name="stop" className="custom-control-input" onChange={()=>this.handleFilterByStops(0)} />
                    <label className="custom-control-label" htmlFor="nonstop">{intl.get('FLIGHT.FILTER_STOPS_DIRECT')}</label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" id="1stop" name="stop" className="custom-control-input" onChange={()=>this.handleFilterByStops(1)} />
                    <label className="custom-control-label" htmlFor="1stop">{intl.get('FLIGHT.FILTER_STOPS_1STOP')}</label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" id="2stop" name="stop" className="custom-control-input" onChange={()=>this.handleFilterByStops(2)} />
                    <label className="custom-control-label" htmlFor="2stop">{intl.get('FLIGHT.FILTER_STOPS_OVER2STOPS')}</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="airlines">
                <h5 className="mb-0"> <a href="#" className="collapse collapsed" data-toggle="collapse" data-target="#toggleAirlines" aria-expanded="false" aria-controls="toggleAirlines">{intl.get('FLIGHT.FILTER_AIRLINES')}</a> </h5>
              </div>
              <div id="toggleAirlines" className="collapse" aria-labelledby="airlines">
                <div className="card-body">
					{this.state.airlines.sort().map((item)=>{
						return <div key={item} className="custom-control custom-checkbox">
                    <input type="checkbox" id={"airline" + item} className="custom-control-input"
					onChange={()=>this.handleFilterByAirline(item)} />
                    <label className="custom-control-label" htmlFor={"airline"+item}>{intl.get('AIRLINE.'+item).defaultMessage(item)}</label>
                  </div>
					})}
                  
                </div>
              </div>
            </div>
			<div className="card">
              <div className="card-header" id="departureTime">
                <h5 className="mb-0"> <a href="#" className="collapse collapsed" data-toggle="collapse" data-target="#toggleDepartureTime" aria-expanded="false" aria-controls="toggleDepartureTime">{intl.get('FLIGHT.FILTER_TIMES')}</a> </h5>
              </div>
              <div id="toggleDepartureTime" className="collapse" aria-labelledby="departureTime">
                <div className="card-body">
					<Box sx={{padding: '0 8px'}}>
				<Typography id="track-inverted-range-slider" gutterBottom>
        {intl.get('FLIGHT.FILTER_TIMES_DEPARTURE')}·{this.state.rangeTime[0] == 0 && this.state.rangeTime[1]==24 ? intl.get('FLIGHT.FILTER_TIMES_UNLIMITED') : `${this.state.rangeTime[0]}:00-${this.state.rangeTime[1]}:00`}
      </Typography>
      <Slider size={"small"}
        getAriaLabel={() => 'Time'}
		min={0}
		max={24}
        value={this.state.rangeTime}
        onChange={this.handleChangeTime}
		valueLabelFormat={valueLabelFormat}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
		step={1}
		onChangeCommitted={this.handleChangeCommitted}
      />
	  </Box>
                </div>
              </div>
            </div>
			<div className="card">
              <div className="card-header" id="flightNumber">
                <h5 className="mb-0"> <a href="#" onClick={this.handleCollapse} className="collapse collapsed" data-toggle="collapse" data-target="#toggleFlightNumber" aria-expanded="false" aria-controls="toggleflightNumber">{intl.get('FLIGHT.FILTER_FLIGHT_NUMBERS')}</a> </h5>
              </div>
              <div id="toggleFlightNumber" className="collapse" aria-labelledby="flightNumber">
                <div className="card-body">
				<Autocomplete
    //   disablePortal
      options={this.state.filterFlightNumbers}
	  onChange={(event, newValue) => {
		console.log('change', newValue);
		this.handleFilterFlightNumber(newValue)
	  }}
    //   sx={{ width: 300 }}
	size="small"
      renderInput={(params) => <TextField hiddenLabel {...params} />}
    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>}

					<div className={"col-lg-"+(this.state.showFilter ? "9" : "12")+" mt-4 mt-lg-0"} style={{backgroundColor: "#ffffff"}}>
					<Box>
<Tabs
        value={this.state.tabValue}
        onChange={this.handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
		{this.state.tabItems.map((item, idx)=>{return <Tab key={item.date} value={item.date} label={<><span className="tab-date">{moment(item.date).format("MMM Do")}</span><span className="tab-price">{intl.get("PRICE", {currency: intl.get("CURRENCY."+ this.state.currency + ".SYMBOL").defaultMessage(this.state.currency), price: item.price})}</span></>}/>})}
        
      </Tabs>
</Box>

						{
						this.state.loaded.length ==0 ? Array.from({ length: 3 }, (v, k) => k).map(item=>{ return <CardSkeleton key={item}/> }) :
						Enumerable.from(this.state.data).orderBy("i=>i.adultBasePrice + i.adultTax").groupBy("i=>i.mainAirlineCode").select("{key:$.key(),value:$.toArray()}").toArray().map((group, index)=>{return <Card key={group.key + group.value.length} airlineCode={group.key} items={group.value} travelers={this.state.travelers} currency={this.state.currency} />})}
						
					</div>
				</div>
				{/* <MyBottomNavigation/> */}
			</section>
			</>
		)
	}
}