import { useEffect, useState } from "react";

const Seat = (props) => {
  // const color = props.availability === 'AVAILABLE' ? "#499167" : "#FE5F55";
  const [color, setColor] = useState(props.selected ? "#1976d2" : (props.selectSeats.findIndex(item=>item.number === props.number) >= 0 ? "#ffc107" : (props.availability === 'AVAILABLE' ? "#499167" : "#FE5F55")))
  const style = {
    position: "absolute",
    left: `${props.y*3}em`, 
    top: `${props.x*3}em`,
    backgroundColor: color,
    color: "white",
    width: "2.8em",
    height: "2.8em",
    cursor: props.availability === 'AVAILABLE' ? "pointer": "not-allowed"
  }


  const handleClick=()=>{
    if(props.availability  !== 'AVAILABLE'){
      return
    }

    // console.log('seat click', props.selectSeats, props.number);

    if(props.selectSeats.findIndex(item=>item.number === props.number) >= 0){
      // props.removeSeat(props.id, props.number)
      props.removeSeat(props.number)
      setColor("#499167")
    }else{
      if(props.selectSeats.length >= props.max){
        alert(`more than ${props.max}`)
      }else{
        // props.addSeat(props.id, props.data)
        props.addSeat(props.data)
        setColor("#ffc107")
      }
    }
  }

  return (
    <div className='seat' style={style} onClick={handleClick}>
<p style={{fontSize: '0.8em', margin: 'auto'}}>{props.number}</p>
<small>${props.price}</small>
    </div>
  )
}

export default Seat;