import React, { Component, useState } from 'react'
import loadCss from '../../common/loadCss'
import intl from 'react-intl-universal';
import ButtonAppBar from '../../components/ButtonAppBar';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import SearchBox from '../../components/SearchBox';
import { ThirtyFpsSharp } from '@mui/icons-material';
import MyBottomNavagation from '../../components/MyBottomNavigation';


function getUrlParam(name) {
	let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
	let r = window.location.search.substr(1).match(reg);  //匹配目标参数
	if (r != null) return unescape(r[2]); return null; //返回参数值
}

export default class Search extends Component {
    state = {
      open: false,
      cid: null
    }

    componentDidMount(){
      // alert("search: " + window.location.search)
      let openid = getUrlParam("p");
      let uid = getUrlParam('u');
      let useId = null;
      let type = null
      if(uid){
          useId = uid
          type = "unionid"
      }else if(openid){
          useId = openid
          type = "openid"
      }

      this.setState({cid: getUrlParam("cid")})
      if(this.props.showClose && !useId){
        openid = sessionStorage.getItem("openid");
      }

      // alert('use id:' + useId)
      if(useId){
        this.setState({open: true})
        if(window.location.pathname.toLocaleLowerCase() == "/search_auth"){
          sessionStorage.setItem("searchAuth", JSON.stringify({search: window.location.search}));
          console.log('auth', window.location.search);
          fetch('api/auths' + window.location.search).then(response=> 
          {
          if(response.ok){
            return response.json()
          }else{
            return Promise.reject(
              response.statusText
            )
          }
        }
          ).then(data=>{
          console.log("auth data", data)
          if(data){
            let json = JSON.parse(data)
            console.log('json', json)
            // console.log('agent info json', JSON.parse(json.agentInfo))
            if(json.agentInfo && json.userInfo){
              let agentInfo = JSON.parse(json.agentInfo)
              agentInfo.AutoTicketEmailAddress = json.userInfo.email
              agentInfo.OpenID = json.userInfo.openid

              agentInfo.HandleAgentEmail = json.userInfo.email
              sessionStorage.setItem("openid", json.userInfo.openid);
              sessionStorage.setItem("userInfo", JSON.stringify(agentInfo));

              console.log('session', sessionStorage.getItem("openid"), sessionStorage.getItem("userInfo"))
            }
          }

          this.setState({open: false})
          }).catch(error=>{
          console.error(error)
          this.setState({open: false})
          });
        }else{
          sessionStorage.clear()
          if(type == "openid"){
            sessionStorage.setItem("openid", openid);
          }
          // this.setState({open: true})
          // fetch('api/users?type=openid&openId=' + openid).then(response=> //response.json()
          fetch('api/users?type=' + type + '&openId=' + useId+'&source=GLYG').then(response=> //response.json()
          {//console.log("response", response); 
          if(response.ok){
            return response.json()
          }else{
            // return Promise.reject({
            //   status: response.status,
            //   statusText: response.statusText
            // })
            return Promise.reject(
              response.statusText
            )
          }
        }
          ).then(data=>{
          console.log("user data", data)
          if(data){
            let json = JSON.parse(data)
              if(type == "unionid"){
                sessionStorage.setItem("openid", json.OpenID);
            }

            if(json.AccountCode && (json.AccountCode.toUpperCase() == "COMTVO" || json.AccountCode == "AIG222")){
              json['type'] = "traveloutlet"
              console.log(window.location);
              if(window.location.href.toLocaleLowerCase().indexOf("thetraveloutlet") < 0){
                console.log(window.location.href.replace(window.location.host, 'wechat.thetraveloutlet.com'))
                window.location = window.location.href.replace(window.location.host, 'wechat.thetraveloutlet.com')
              }
            }
            // if(openid == "oead2wQTEvWad1TG5YIJUwHWOGac"){
            //   json["type"] = "traveloutlet"
            // }
            sessionStorage.setItem("userInfo", JSON.stringify(json));
          }

          this.setState({open: false})
          }).catch(error=>{
          console.error(error)
          this.setState({open: false})
        });
      }
      }else{
        sessionStorage.clear()
      }

        document.title = intl.get("SEARCH.TITLE")

        loadCss('font-awesome/css/all.min.css');
        loadCss('css/MVstyle-2022.css');
        loadCss('css/bootstrap.min.css');
        loadCss('css/jquery-ui/jquery-ui.min.css');
        loadCss('Flight-Hotel/vendor/daterangepicker/daterangepicker.css');
        loadCss('css/stylesheet.css');
        loadCss('css/search.css');

        let loadScript=(path,async)=>{
            const script = document.createElement("script")
            script.src = process.env.PUBLIC_URL + path
            script.async = async
      
            document.body.appendChild(script)
        }
        loadScript('/js/jquery-ui.min.js')
        loadScript('/js/bootstrap-spinner.js')
        loadScript('/js/theme.js')
        loadScript('/js/moment.min.js')
        loadScript('/js/daterangepicker.js')
        loadScript('/js/search.js')
    }

    // handleClick=(e)=>{
    //   e.preventDefault();
    //   let airportPattern = new RegExp("\(([A-Z]{3})\)");
    //   let from = document.getElementById("flightFrom").value;
    //   let to = document.getElementById("flightTo").value;
    //   let departDate = document.getElementById("flightDepart").value;
    //   let returnDate = document.getElementById("flightReturn").value;
    //   let adults = document.getElementById("adulttravellers").value;
    //   let children = document.getElementById("childrentravellers").value;
    //   let fromMatch = airportPattern.exec(from);
    //   let fromCode = null;
    //   let toCode = null;
    //   if (fromMatch == null) {
    //     fromCode = from;
    //   }
    //   else {
    //     fromCode = fromMatch[1];
    //   }

    //   let toMatch = airportPattern.exec(to);
    //   if (toMatch == null) {
    //     toCode = to;
    //   }
    //   else {
    //     toCode = toMatch[1];
    //   }

    //   let departDateFormat = moment(departDate).format("YYYYMMDD");
    //   let returnDateFormat = null;
    //   if (!!returnDate) {
    //     returnDateFormat = moment(returnDate).format("YYYYMMDD");
    //   }

    //   let localStorage = window.localStorage;
    //   localStorage.from = from;
    //   localStorage.to = to;
    //   localStorage.departDate = departDate;
    //   localStorage.returnDate = returnDate;
    //   window.location = `/flight?flights=${fromCode}/${toCode}/${departDateFormat}${returnDateFormat != null ? "/" + returnDateFormat : ""}&cabin=${this.state.cabin}&adults=${adults}&children=${children}&infants=0`;				
    // }

    // changeCabin=(e)=>{
    //   this.setState({cabin: e.target.value})
    // }

    // handleFocus=(dataType)=>{
    //   // console.log("focus")
    //   return(e)=>{
    //     // console.log('focus', {history: {[dataType]: this.state.data[dataType]}})
    //     this.setState({history: {[dataType]: this.state.data[dataType]}})
    //     this.setState({data: {...this.state.data, [dataType]:''}})
    //   }
    // }

    // handleBlur=(dataType)=>{
    //   // console.log('blur')
    //   return(e)=>{
    //     // console.log('blur', dataType, e.target.value)
    //     if(!e.target.value){
    //       this.setState({data: {...this.state.data, [dataType]: this.state.history[dataType]}})
    //     }else{
    //       this.setState({data: {...this.state.data, [dataType]: e.target.value}})
    //     }
    //   }
    // }

    // handleChange=(dataType)=>{
    //   // console.log('dataType', dataType, this.state.data)
    //   return (e)=>{
    //     // console.log(dataType, e.target.value)

    //     // console.log('change', {...this.state.data, [dataType]: e.target.value})
    //     this.setState({data: {...this.state.data, [dataType]: e.target.value}})
    //   }
    // }


  render() {
    return (         
    <>
        <ButtonAppBar/>
        {/* <h6>search: {window.location.search}</h6> */}
        <section className="page-header page-header-text-light bg-secondary">
        <div id="content">
          <section className="container-Search">
              <div className="bg-white shadow-md rounded p-4">
                <div className="row">
                  <SearchBox {...this.props} cid={this.state.cid}/>
                </div>
              </div>
            </section>
          </div>
          <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={this.state.open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
          </section>
          {/* <MyBottomNavagation/> */}
    </>
    )
  }
}