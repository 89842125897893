import React from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box';
import { Alert, Backdrop, CircularProgress, Grid, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import { useEffect } from 'react';
import { OptionGroupUnstyled } from '@mui/base';
import intl from 'react-intl-universal';
import moment from 'moment';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router-dom';
import ProgressBox from '../../components/ProgressBox';
import style from './index.module.css'
import ButtonAppBar from '../../components/ButtonAppBar';
import { AppBar, LinearProgress } from '@mui/material'


function parseOrderStatus(opStatus){
  if(opStatus == "New"){
    return 1
  }else if(opStatus == "Processing"){
    return 2
  }else if(opStatus == "Issued"){
    return 3
  }else if(opStatus == "Cancelled"){
    return 4
  }

  return 0
}

function Item(props){
    const navigate = useNavigate()
    const[sqlResult, setSqlResult] = useState(null)
    const[open, setOpen] = useState(false)
    const[contact, setContact] = useState(null)
    
    function handleClick(){       
        if(sqlResult){
            navigate(`/success`, {state: {bookInfo: {routing: sqlResult.routingText, passengers: sqlResult.passengers, 
              adults: sqlResult.order.adults, children: sqlResult.order.children, openid: props.openid,
              contact: contact }, 
            bookedRsp: {airTicketBookingResponse: {recordLocator: props.recordLocator, confirmationCode: props.confirmationCode, 
              paymentLink: sqlResult.order.shortLink}},
            currency: sqlResult.order.currency, orderTime: props.bookingDate, orderStatus: parseOrderStatus(props.opStatus)
        }});
        }else{
            setOpen(true)
        fetch(`api/orders?caseNumber=${props.confirmationCode}&count=2`).then(response=> {
        if(response.ok){
          return response.json()
        }else{
          return Promise.reject(
            response.statusText
          )
        }
      }
      ).then(data=>{
        setOpen(false)
        let jsonData = JSON.parse(data)
        let apiResultJson = JSON.parse(jsonData.apiResult)
        let sqlResultJson = JSON.parse(jsonData.sqlResult)
        for(let passenger of sqlResultJson.passengers){
          for(let apiPassenger of apiResultJson.order.passengers){
            if(passenger.firstName == apiPassenger.firstName && passenger.lastName == apiPassenger.lastName){
              passenger.tktnumber=apiPassenger.ticketNumber
            }
          }
        }

        setSqlResult(sqlResultJson)
        setContact(apiResultJson.order.contact)
        navigate(`/success`, {state: {bookInfo: {routing: sqlResultJson.routingText, passengers: sqlResultJson.passengers, 
          adults: sqlResultJson.order.adults, children: sqlResultJson.order.children, openid: props.openid,
          contact: apiResultJson.order.contact }, 
        bookedRsp: {airTicketBookingResponse: {recordLocator: props.recordLocator, confirmationCode: props.confirmationCode, 
          paymentLink: sqlResultJson.order.shortLink}},
        currency: sqlResultJson.order.currency, orderTime: props.bookingDate, orderStatus: parseOrderStatus(props.opStatus)
    }});
      }).catch(error=>{
        console.error(error)
        setOpen(false)
        alert(error)
      });
    }
    }

    function handlePayment(){
      if (props.opStatus == "Cancelled" || props.opStatus == "Issued"){
        return
      }

        if(sqlResult){
            navigate(`/payment/` + sqlResult.order.shortLink)
        }else{
          setOpen(true)
          fetch(`api/orders?caseNumber=${props.confirmationCode}&count=2`).then(response=> {
          if(response.ok){
            return response.json()
          }else{
            return Promise.reject(
              response.statusText
            )
          }
        }
        ).then(data=>{
          setOpen(false)
          let jsonData = JSON.parse(data)
          let apiResultJson = JSON.parse(jsonData.apiResult)
          let sqlResultJson = JSON.parse(jsonData.sqlResult)
          for(let passenger of sqlResultJson.passengers){
            for(let apiPassenger of apiResultJson.order.passengers){
              if(passenger.firstName == apiPassenger.firstName && passenger.lastName == apiPassenger.lastName){
                passenger.tktnumber=apiPassenger.ticketNumber
              }
            }
          }
          setSqlResult(sqlResultJson)
          navigate(`/payment/` + sqlResultJson.order.shortLink)
        }).catch(error=>{
          setOpen(false)
          console.error(error)
          alert(error)
        });   
    }
    }

    return(
        <>
        <Grid item xs={12} md={6}>
        <Grid item xs container direction="column" spacing={2}>
        <Paper sx={{padding: "5px"}}>
        <Grid item xs>
          <Typography gutterBottom variant="subtitle1" component="div">
          {`${intl.get("AIRPORT." + props.departure.toUpperCase()+".CITYNAME")}-${intl.get("AIRPORT." + props.arrival.toUpperCase()+".CITYNAME")} (${props.recordLocator})`}

          <IconButton color="primary" aria-label="display trip detail" onClick={handleClick}>
                    <AddCircleOutlineIcon />
</IconButton>
<small onClick={handlePayment} className={props.opStatus =="Cancelled" ? style.cancelledStatus : style.status}>
  { props.opStatus == "New" && props.paymentStatus == "No Payment" ? props.paymentStatus: props.opStatus}</small>
                    </Typography>

                    </Grid>
        <Grid item xs>
        <Typography variant="body2" color="text.secondary">
        {`${moment(props.departureTime).format("MMM Do YYYY")}${props.arrivalTime ? " - " + moment(props.arrivalTime).format("MMM Do YYYY") : ""}`}
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom>
        Order time: {moment(props.bookingDate).format("YYYY/MM/DD HH:mm:ss")}
      </Typography>
        </Grid>
        </Paper>
        </Grid>
        </Grid>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
        </>
    )
}

function getUrlParam(name) {
	let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
	let r = window.location.search.substr(1).match(reg);  //匹配目标参数
	if (r != null) return unescape(r[2]); return null; //返回参数值
}

export default function Order() {
    const [orders, setOrders] = useState([])
    const [loaded, setLoaded] = useState(false)
    // const [groups, setGroups] = useState([])
    const navigate = useNavigate()
    

    useEffect(()=>{
        let caseNumber = getUrlParam('caseNumber');
        if(caseNumber){
        fetch(`api/orders?caseNumber=${caseNumber}&count=2`).then(response=> {
        if(response.ok){
          return response.json()
        }else{
          return Promise.reject(
            response.statusText
          )
        }
      }
      ).then(data=>{
        setLoaded(true)
        let jsonData = JSON.parse(data)
        let apiResultJson = JSON.parse(jsonData.apiResult)
        let sqlResultJson = JSON.parse(jsonData.sqlResult)
        for(let passenger of sqlResultJson.passengers){
          for(let apiPassenger of apiResultJson.order.passengers){
            if(passenger.firstName == apiPassenger.firstName && passenger.lastName == apiPassenger.lastName){
              passenger.tktnumber=apiPassenger.ticketNumber
            }
          }
        }

        // console.log('case number', props)

        navigate(`/success`, {state: {bookInfo: {routing: sqlResultJson.routingText, passengers: sqlResultJson.passengers, 
          adults: sqlResultJson.order.adults, children: sqlResultJson.order.children, openid: null,
          contact: apiResultJson.order.contact }, 
        bookedRsp: {airTicketBookingResponse: {recordLocator: apiResultJson.order.pnr, confirmationCode: apiResultJson.order.caseNumber,paymentLink: sqlResultJson.order.shortLink}},
        currency: sqlResultJson.order.currency, orderTime: new Date(), orderStatus: parseOrderStatus(apiResultJson.order.status)
    }});
      }).catch(error=>{
        setLoaded(true)
        console.error(error)
        alert(error)
      });
        }else{
        let openid = getUrlParam("p");
        if(!openid || sessionStorage.getItem("searchAuth")){
            openid = sessionStorage.getItem("openid")
        }

        if(openid){
            fetch('api/orders?openid=' + openid).then(response=> response.json()
            
          ).then(data=>{
            // console.log("order data", data);
            if(data){
            let json = JSON.parse(data)
            let items = []
            for(let orderDetail of json.airTicketOrderResponse){
                // let trips = orderDetail.order.trip.split('/')
                // console.log("trips", trips);
                items.push({departure: orderDetail.departureAirportCode, arrival: orderDetail.destinationAirportCode, 
                    departureTime: orderDetail.departureDateTime, 
                    arrivalTime: orderDetail.returnDateTime, 
                    routing: null, 
                recordLocator: orderDetail.recordLocator, confirmationCode: orderDetail.caseNumber, 
                passengers: orderDetail.passengerInfo,
                currency: "USD",
                paymentStatus: orderDetail.paymentStatus,
                opStatus: orderDetail.opStatus,
                bookingDate: orderDetail.bookingDate,
                openid
                // shortUrl: orderDetail.order.shortLink
            })
            }

            setOrders(items)
          }
            setLoaded(true)
          }).catch(error=>{
            console.error(error)
            alert(error);
            setLoaded(true)
          });
        }else{
          setLoaded(false)

          alert('NOT FOUND');
        }
      }

    },[])


  return (
    <>
    <ButtonAppBar/>
    {loaded || <LinearProgress />}

    <Box sx={{ bgcolor: '#e7e9ed', padding: "24px" }} >
    <Typography variant="h6" gutterBottom>
      {intl.get("MYORDERS.ORDER_TITLE")}
        </Typography>
        {loaded && orders.length == 0 && <Alert severity="warning">NOT FOUND</Alert>}
            <Grid container sx={{marginTop: 'auto'}} spacing={2} rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {orders.map((item, i)=>{return <Item key={i} {...item}/>})}
            </Grid>
                {/* {groups.map((group, i)=>{return <Grid key={i} container spacing={2}>{group.map((item, j)=>{return <Item key={j} {...item}/>})}</Grid>})} */}
        </Box>
    </>
  )
}
