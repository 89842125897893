import React from 'react'
import style from './index.module.css'


export default function index() {
  
  return (
    <div className={style.contentLoder}>
    <div className="card-body text-center">
      <div className="row">
        <div className="col-12">
           <div className="p-6 m-3 d-inline-block">
                <div className="ball-spin-fade-loader">
                      <div className={style.bgPrimary}></div>
                      <div className={style.bgPrimary}></div>
                      <div className={style.bgPrimary}></div>
                      <div className={style.bgPrimary}></div>
                      <div className={style.bgPrimary}></div>
                      <div className={style.bgPrimary}></div>
                      <div className={style.bgPrimary}></div>
                      <div className={style.bgPrimary}></div>
                </div>
              </div>
        </div>
     </div>
    </div>
</div>
  )
}
