import React, { useEffect, useState } from 'react'
import intl from 'react-intl-universal';
import IconButton from '@mui/material/IconButton';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import moment from 'moment/moment';
import './index.css'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';

export default function SearchBox(props) {
  const[data, setData] = useState({from: "", to: "", cabin: ""})
  const[history, setHistory] = useState({from: "", to: ""})
  // const[returnMinDate, setReturnMinDate] = useState(new Date())
  useEffect(()=>{
    let localStorage = window.localStorage;
    if(localStorage.from){
      setData({from: localStorage.from, to: localStorage.to, departDate: localStorage.departDate, returnDate: localStorage.returnDate, cabin: ''})
    //   this.setState({data: })
    }else{
      fetch('https://ipapi.co/json/')
      .then((res)=>{
      return res.json()
      })
      .then((res)=>{
      // console.log(res)

      fetch(`https://scholartrip.com//geonames/api/v1/airport/nearest?lat=${res.latitude}&lon=${res.longitude}&lang=en`)
      .then((res)=>{
      return res.json()
      })
      .then((res)=>{
      // console.log(res)
      for(let airport of res.data){
        setData({...data, from: airport.iata})
        // this.setState({data: {...this.state.data, from: airport.iata}})
        break
      }	
      }).catch((error) => {
      console.error(error, typeof(error));
      });

      }).catch((error) => {
      console.error(error, typeof(error));
      });
    }
  },[])

  const handleFocus=(dataType)=>{
    // console.log('focus', dataType);
    setHistory({...history, [dataType]: data[dataType]})
    setData({...data, [dataType]: ''})

  } 
  
  const handleBlur=(dataType, e)=>{
    // console.log('blur', dataType)
    if(!e.target.value){
        setData({...data, [dataType]: history[dataType]})
    }else{
        setData({...data, [dataType]: e.target.value})
    }
  }

  const handleSwitch = ()=>{
    // console.log('switch')
    let from = document.getElementById("flightFrom").value;
    let to = document.getElementById("flightTo").value;
    document.getElementById("flightFrom").value = to;
    document.getElementById("flightTo").value = from;
  }

  const handleChange=(dataType, e)=>{
    // console.log('change', dataType, {...data, [dataType]: e.target.value})
    setData({...data, [dataType]: e.target.value})
  }

  const changeCabin=(e)=>{
    setData({...data, cabin: e.target.value})
  }

  const handleClick=(e)=>{
    e.preventDefault();
    let airportPattern = new RegExp("\(([A-Z]{3})\)");
    let from = document.getElementById("flightFrom").value;
    let to = document.getElementById("flightTo").value;
    let departDate = data.departDate;
    if(departDate == null){
      departDate = ''
    }
    let returnDate = data.returnDate;
    if(returnDate == null){
      returnDate = ''
    }
    let adults = document.getElementById("adulttravellers").value;
    let children = document.getElementById("childrentravellers").value;
    let fromMatch = airportPattern.exec(from);
    let fromCode = null;
    let toCode = null;
    if (fromMatch == null) {
      fromCode = from;
    }
    else {
      fromCode = fromMatch[1];
    }

    let toMatch = airportPattern.exec(to);
    if (toMatch == null) {
      toCode = to;
    }
    else {
      toCode = toMatch[1];
    }

    let departDateFormat = moment(departDate).format("YYYYMMDD");
    let returnDateFormat = null;
    if (returnDate) {
      returnDateFormat = moment(returnDate).format("YYYYMMDD");
    }

    let localStorage = window.localStorage;
    localStorage.from = from;
    localStorage.to = to;
    localStorage.departDate = departDate;
    localStorage.returnDate = returnDate;
    window.location = `/flight?flights=${fromCode}/${toCode}/${departDateFormat}${returnDateFormat != null ? "/" + returnDateFormat : ""}&cabin=${data.cabin}&adults=${adults}&children=${children}&infants=0` + (props.cid ? "&cid="+ props.cid : "");				
  }

  return (
    <div className="col-lg-12 mb-4 mb-lg-0">
    <form id="bookingFlight" method="post">
     <div className="form-row">
        <div className="col-lg-6 form-group">
          <input type="text" onFocus={()=>handleFocus('from')} onBlur={(e)=>handleBlur('from', e)} onChange={(e)=>handleChange('from', e)} value={data.from} className="form-control" id="flightFrom" required placeholder={intl.get("SEARCH.FROM")} disabled={props.disabled}/>
            </div>
        <div className="col-lg-6 form-group">
          <input type="text" onFocus={()=>handleFocus('to')} onBlur={(e)=>handleBlur('to', e)} onChange={(e)=>handleChange('to', e)} value={data.to} className="form-control" id="flightTo" required placeholder={intl.get("SEARCH.TO")} disabled={props.disabled}/>
          {/* <span className="icon-inside">
            <i className="fas fa-map-marker-alt"></i>
          </span> */}
        </div>

      </div>
        
      <div className="form-row">
          <div className="col-md-6 col-lg-3 form-group">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
          label={intl.get("SEARCH.DEPARTDATE")}
          value={data.departDate || new Date()}
          minDate={new Date()}
          onChange={(newValue) => {
            // setValue(newValue);
            setData({...data, departDate: newValue})
          }}
          renderInput={(params) => <TextField {...params} autoComplete="off"/>}
        />
        </LocalizationProvider>
              {/* <input id="flightDepart" name="dates" type="text" className="form-control" defaultValue={data.departDate} required placeholder={intl.get("SEARCH.DEPARTDATE")} autoComplete='off'/> */}
              {/* <span className="icon-inside"><i className="far fa-calendar-alt"></i></span> */}
          </div>
          <div className="col-md-6 col-lg-3 form-group">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
          label={intl.get("SEARCH.RETURNDATE")}
          value={data.returnDate || null}
          minDate={data.departDate || new Date()}
          defaultCalendarMonth={data.departDate ? new Date(data.departDate) : new Date()}
          onChange={(newValue) => {
            // setValue(newValue);
            setData({...data, returnDate: newValue})
          }}
          componentsProps={{
            actionBar: {
              actions: ["clear"]
            }
          }}
          renderInput={(params) => <TextField {...params} autoComplete="off" />}
        />
        </LocalizationProvider>
              {/* <input id="flightReturn" name="dates" type="text" className="form-control"  defaultValue={data.returnDate} placeholder={intl.get("SEARCH.RETURNDATE")} autoComplete="off"/>
              <span className="icon-inside"><i className="far fa-calendar-alt"></i></span> */}
          </div>

          
          <div className="col-md-6 col-lg-3 travellers-class form-group">
                            <input type="text" id="hotelsTravellersClass" name="hotelRoomsTravellers" className="travellers-class-input form-control" autoComplete="off" placeholder="Travelers" required onKeyPress={()=>{return false;}} defaultValue={"1" + intl.get("SEARCH.TRAVELER")} disabled={props.disabled}/>
                            <span className="icon-inside"><i className="fas fa-caret-down"></i></span>
                            <div className="roomtravellers-dropdown travellers-dropdown" style={{display: "none"}}>
                                <div className="row align-items-center">
                                    <div className="col-sm-7">
                                        <p className="mb-sm-0">{intl.get("SEARCH.ADULTS")} <small className="text-muted">(12+ {intl.get("SEARCH.YEAR")})</small></p>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="qty input-group">
                                            <div className="input-group-prepend">
                                                <button type="button" className="btn bg-light-4" data-value="decrease" data-target="#adulttravellers" data-toggle="spinner">-</button>
                                            </div>
                                            <input type="text" data-ride="spinner" id="adulttravellers" name="adult-travellers" className="qty-spinner form-control" defaultValue="1" readOnly/>
                                            <div className="input-group-append">
                                                <button type="button" className="btn bg-light-4" data-value="increase" data-target="#adulttravellers" data-toggle="spinner">+</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="my-2"/>
                                <div className="row align-items-center">
                                    <div className="col-sm-7">
                                        <p className="mb-sm-0">{intl.get("SEARCH.CHILDREN")} <small className="text-muted">(1-12 {intl.get("SEARCH.YEAR")})</small></p>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="qty input-group">
                                            <div className="input-group-prepend">
                                                <button type="button" className="btn bg-light-4" data-value="decrease" data-target="#childrentravellers" data-toggle="spinner">-</button>
                                            </div>
                                            <input type="text" data-ride="spinner" id="childrentravellers" name="children-travellers" className="qty-spinner form-control" value="0" readOnly/>
                                            <div className="input-group-append">
                                                <button type="button" className="btn bg-light-4" data-value="increase" data-target="#childrentravellers" data-toggle="spinner">+</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button className="btn btn-primary btn-block submit-done mt-3" type="button">{intl.get("SEARCH.DONE")}</button>
                            </div>
                        </div>
          
          
          
          <div className="col-md-6 col-lg-3 travellers-class form-group">
                            <input type="text" id="flightClass" className="travellers-class-input form-control" required autoComplete="off" name="flightClass" placeholder={intl.get("CABIN.E")} readOnly disabled={props.disabled} onKeyPress={()=>{return false;}}/>
                            <span className="icon-inside"><i className="fas fa-caret-down"></i></span>
                            <div className="cabin-dropdown travellers-dropdown" style={{display: "none"}}>
                                <div className="mb-3" onChange={changeCabin}>
                                    {/* <div className="custom-control custom-radio">
                                        <input id="flightClassAll" name="flight-class" className="flight-class custom-control-input" value="" defaultChecked type="radio"/>
                                        <label className="custom-control-label" htmlFor="flightClassAll">All</label>
                                    </div> */}
                                    <div className="custom-control custom-radio">
                                        <input id="flightClassEconomic" name="flight-class" className="flight-class custom-control-input" value="E" defaultChecked type="radio"/>
                                        <label className="custom-control-label" htmlFor="flightClassEconomic">{intl.get("CABIN.E")}</label>
                                    </div>
                                    <div className="custom-control custom-radio">
                                        <input id="flightClassPremiumEconomic" name="flight-class" className="flight-class custom-control-input" value="P" type="radio"/>
                                        <label className="custom-control-label" htmlFor="flightClassPremiumEconomic">{intl.get("CABIN.P")}</label>
                                    </div>
                                    <div className="custom-control custom-radio">
                                        <input id="flightClassBusiness" name="flight-class" className="flight-class custom-control-input" value="B" type="radio"/>
                                        <label className="custom-control-label" htmlFor="flightClassBusiness">{intl.get("CABIN.B")}</label>
                                    </div>
                                    <div className="custom-control custom-radio">
                                        <input id="flightClassFirstClass" name="flight-class" className="flight-class custom-control-input" value="F" type="radio"/>
                                        <label className="custom-control-label" htmlFor="flightClassFirstClass">{intl.get("CABIN.F")}</label>
                                    </div>
                                </div>
                                <button className="btn btn-primary btn-block submit-done mt-3" type="button">{intl.get("SEARCH.DONE")}</button>
                            </div>
                        </div>
  </div> 
  
        
      {/* <div className="hotel-part">
          <div className="custom-control custom-radio custom-control-inline">
              <input id="checked" name="checked" className="custom-control-input" required type="radio"/>
              <label className="custom-control-label" htmlhtmlFor="checked">
                <h6 style={{lineHeight: "24px"}}>I only need a hotel for part of my stay</h6>
              </label>
          </div>
      </div>	 */}
        
  
  <div id="bookingHotels" method="post">
    <div className="form-row">
      <div className="col-lg-3 form-group">
      {props.showClose && <button className="btn btn-light btn-block" type="button" onClick={()=>{props.changeShowType(false)}}>{intl.get("SEARCH.CLOSE")}</button>}
      </div>
      <div className="col-lg-3 form-group">
      <IconButton className="SwitchIcon" color="primary" aria-label="switch" component="label" onClick={handleSwitch}>
        <ConnectingAirportsIcon/>
      </IconButton>
        </div>
  <div className="col-lg-3 form-group">
        <button className="btn btn-primary btn-block" type="submit" onClick={handleClick}>{intl.get("SEARCH.SEARCH")}</button>
      </div>
    </div>
  </div>
   </form>
  
      
  </div>
  )
}
