// import logo from './logo.svg';
import Flight from './pages/Flight'
import Search from './pages/Search';
import {Route, Routes} from "react-router-dom";
import NotFound from './pages/NotFound'
import Loading from './components/Loading'
import Book from './pages/Book';
import Demo from './pages/Demo'
import Success from './pages/Success';
import Share from './pages/Share';
import Payment from './pages/Payment';
import Order from './pages/Order';
import PaymentResult from './pages/PaymentResult';
import Package from './pages/Package';
import{ useEffect, useState } from 'react'
import intl from 'react-intl-universal';
import moment from 'moment';
import Hotel from './pages/Hotel';
import HotelDetail from './pages/HotelDetail';
import HotelBook from './pages/HotelBook';
import HotelInput from './pages/HotelInput';
import Seatmap from './pages/Seatmap';
import SeatmapDemo from './pages/SeatmapDemo';


const locales = {
	"en-us": require('../src/locales/en-US.json'),
    "zh-cn": require('../src/locales/zh-CN.json'),
}

function App() {
  const [initDone, setInitDone] = useState(false)
  function loadLocales(){
    let currentLocale = intl.determineLocale({
        urlLocaleKey: 'lang',
        cookieLocaleKey: 'lang',
        localStorageLocaleKey: 'lang'
    })

    if(!currentLocale){
      currentLocale = "en-US"
    }else if(currentLocale.length == 2){ 
      if(currentLocale.toLocaleLowerCase() == "en"){
        currentLocale = "en-US"
      }else if(currentLocale.toLocaleLowerCase() == "zh"){
        currentLocale = "zh-CN"
      }
    }

    moment.locale(currentLocale)
    // console.log('app', locales)
    // locales['en-us']["DEMO"] = "demo1"
    // locales['zh-cn']["DEMO"] = "测试"
    intl.init({
        currentLocale: currentLocale.toLowerCase(),
        locales:{[currentLocale.toLocaleLowerCase()]: locales[currentLocale.toLocaleLowerCase()]}
    })
    .then(()=>{
        setInitDone(true)
    })
}

  useEffect(()=>{
    loadLocales()
  },[])

  return (
    initDone &&
    <Routes>
        <Route path='/search' element={<Search/>}/>
        <Route path='/search_auth' element={<Search/>}/>
        <Route path='/flight' element={<Flight/>}/>
        <Route path='/book' element={<Book/>}/>
        <Route path='/success' element={<Success/>}/>
        <Route path='/' element={<Search/>}/>
        <Route path="*" element={<NotFound />}/>
        <Route path='/loading' element={<Loading/>}/>
        <Route path='/order' element={<Order/>}/>
        <Route path='/share/:key' element={<Share/>}/>
        <Route path='/payment/:key' element={<Payment/>}/>
        {/* <Route path='/demo' element={<Demo/>}/> */}
        <Route path='/PaymentResult' element={<PaymentResult/>}/>
        <Route path='/package' element={<Package/>}/>
        <Route path='/hotel' element={<Hotel/>}/>
        <Route path='/hotelDetail' element={<HotelDetail/>}/>
        <Route path='/hotelBook' element={<HotelBook/>}/>
        <Route path='/hotelInput' element={<HotelInput/>}/>
        <Route path='/seatmap' element={<Seatmap/>}/>
        <Route path='/seatmapdemo' element={<SeatmapDemo/>}/>
        <Route path='/demo' element={<Demo/>}/>
    </Routes>
  );
}

export default App;
